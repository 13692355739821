<template>
	<div class="pt-0 w-100 historic-notification-chart">
		<v-form v-model="pollutantsForm" ref="form" style="max-height: 91px; overflow: hidden auto">
			<v-row no-gutters class="px-3 pt-2">
				<!-- Design options -->
				<v-col cols="6" class="d-flex justify-start align-center" v-if="form.showChartOptions || form.option">
					<v-select
						v-model="form.designOption"
						@input="changeVisualization"
						class="form-control-select px-1"
						:items="form.configurations"
						hide-details
						required
						:label="$t('panels.device.designOptions')"
					>
						<template v-slot:item="{ item }">
							<v-tooltip
								v-if="item.value.description"
								right
								nudge-right="20"
								:max-width="$vuetify.breakpoint.md ? 350 : 650"
								:color="primaryColor"
							>
								<template v-slot:activator="{ on, attrs }">
									<div class="d-flex" v-bind="attrs" v-on="on">
										<v-icon class="px-2 fw-500 fs-md">fa-kit fa-line-info-2</v-icon>
										<span>{{ item.text }}</span>
									</div>
								</template>
								<span class="fw-regular fs-regular text-center">{{ item.value.description }}</span>
							</v-tooltip>
							<span v-else class="pl-2">{{ item.text }}</span>
						</template>
					</v-select>
					<echart-show-interval
						v-if="enableShowIntervals"
						:elasticData="form.chartData"
						:designOption="form.designOption"
						:pollutant="pollutant"
						:showIntervals="form.showIntervals"
						@toggleShowIntervals="toggleShowIntervals"
						:height="40"
						spacingClass="mt-4"
					></echart-show-interval>
					<echart-export-excel
						v-if="form.designOption && form.designOption.exportable == 1"
						:option="form.option ?? {}"
						:designOption="form.designOption"
						:pollutant="pollutant"
						:dateInterval="[form.startDate, form.endDate]"
						:bgColor="primaryColor"
						iconColor="white"
						:chartInstance="chartInstance"
						:height="40"
						spacingClass="mt-4"
					></echart-export-excel>
				</v-col>
			</v-row>
		</v-form>
		<!-- Loading animation -->
		<div class="d-flex justify-center align-center" v-if="loadingAnimation && !form.option" :style="chartStyle">
			<v-progress-circular indeterminate :color="primaryColor"></v-progress-circular>
		</div>
		<v-chart
			v-if="form.option"
			:loading="loadingAnimation"
			:option="form.option"
			:style="chartStyle"
			autoresize
			ref="historicNotificationChart"
		></v-chart>
	</div>
</template>

<script>
import { search, getDateByFormat, ChartOptions, obtainIndexName, getStatusProperties } from '@/api/common';

import { lastData } from '@/api/databases_API';
import { query_HistoricDataByParameter } from '@/utils/queries';

import constants from '@/utils/constants';
import EchartExportExcel from './functionalities/EchartExportExcel.vue';
import EchartShowInterval from './functionalities/EchartShowInterval.vue';
import EchartShowIntervalMixin from './functionalities/EchartShowIntervalMixin';

import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { BarChart, LineChart } from 'echarts/charts';
import {
	TitleComponent,
	TooltipComponent,
	LegendComponent,
	ToolboxComponent,
	GridComponent,
	DataZoomComponent,
	VisualMapComponent,
	MarkLineComponent,
	MarkPointComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

use([
	TitleComponent,
	TooltipComponent,
	LegendComponent,
	ToolboxComponent,
	GridComponent,
	DataZoomComponent,
	VisualMapComponent,
	MarkLineComponent,
	MarkPointComponent,
	BarChart,
	LineChart,
	CanvasRenderer
]);

export default {
	name: 'HistoricNotificationChart',
	mixins: [EchartShowIntervalMixin],
	components: {
		VChart,
		EchartExportExcel,
		EchartShowInterval
	},
	data() {
		return {
			width: '100%',
			height: '100%',
			chart: null,
			pollutantNoSelected: true,
			xData: [],
			series: [],

			form: {
				index: this.pollutant.elasticacronym,
				dateInterval: null,
				startDate: null,
				endDate: null,
				formDisabled: false,
				startDateDisabled: true,
				endDateDisabled: true,
				infoStartDate: this.$t('aqi.infoDateIntervalDisabled'),
				infoEndDate: this.$t('aqi.infoDateIntervalDisabled'),
				chartData: {},
				designOption: null,
				configurations: [],
				showChartOptions: false,
				option: null
			},

			pollutantsForm: false,
			loadingAnimation: false,
			chartType: '',
			historicalChartData: {},
			chartStyle: {},
			parameterConfiguration: {},
			showAdvancedDate: false,
			primaryColor: this.$store.state.global.primaryColor,
			parameterOption: null,
			chartInstance: null
		};
	},
	props: {
		feature: {
			type: Object,
			required: true
		},
		pollutant: {
			type: Object,
			required: true
		},
		displayLocation: {
			type: String,
			required: true
		},
		notificationSource: {
			type: Object,
			required: true
		}

		/* chartData: {
			type: Array,
			required: true
		} */
	},
	computed: {
		pollutants() {
			return this.notificationSource
				? this.$store.getters.getParameters.filter((parameter) => parameter.pmdatasourcetypeid == this.notificationSource.datasourcetype_id)
				: [];
		},
		rule() {
			return this.$store.getters.getRules.find((rule) => rule.acronym == this.notificationSource['rule_name']);
		},
		historicElasticData() {
			return this.$store.getters.getHistoricElasticData[this.pollutant.elasticacronym];
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		},
		dialogHeight() {
			if (this.displayLocation === 'dialog') {
				return document.getElementById('dialog-container').clientHeight;
			}
			return false;
		},
		selectedTabNotificationDialog: {
			get() {
				return this.$store.getters.getSelectedTabNotificationDialog;
			},
			set(value) {
				this.$store.commit('setSelectedTabNotificationDialog', value);
			}
		}
	},

	watch: {
		'form.option': {
			handler(newVal) {
				if (newVal) {
					this.$nextTick(() => {
						if (this.$refs['historicNotificationChart']) {
							this.chartInstance = this.$refs['historicNotificationChart'];
							console.log('Chart instance initialized:', this.chartInstance);
						} else {
							console.error('Chart instance is not available');
						}
					});
				}
			},
			immediate: true
		},
		'form.designOption': function (newValue, oldValue) {
			console.log('log: debug design option from: ', oldValue, ' to: ', newValue);
		},
		selectedTabNotificationDialog: {
			handler(newValue, oldValue) {
				console.log('debug notification dialog tab', oldValue, newValue);
			}
		}
	},
	created() {
		console.log('===== created notif chart ' + this.displayLocation, this.formData, this.form, this.selectedTabNotificationDialog);

		//this.load();
	},
	mounted() {
		console.log('======= mounted notif chart ' + this.displayLocation, this.form, this.notificationSource, this.selectedTabNotificationDialog);
		this.parameterOption = this.configurationByPollutant(this.pollutant);
		this.parameterConfiguration = this.parameterOption.value.config;
		this.loadDesignOptions();
		if (!this.form.designOption) {
			this.form.designOption = this.form.configurations.find((option) => option.value.id == this.parameterOption.value.id).value;
		}
		this.chartStyle = this.displayLocation == 'card' ? { height: '250px', width: '100%' } : { height: 'calc(83vh - 250px)', width: '83vw' };
		if (this.notificationSource) {
			this.form.startDate = this.notificationSource['start_date'];
			this.form.endDate = this.notificationSource['end_date'];
			if (!Object.hasOwn(this.feature, 'code')) this.feature.code == this.notificationSource.device;
			this.submitEndDate();
		}
	},
	updated() {
		console.log('======= ' + this.displayLocation + ' updated', this.formHistoric, this.form);
	},
	beforeDestroy() {
		console.warn(this.displayLocation + ' beforeDestroy historicChart');
		this.deleteChart();
	},
	methods: {
		deleteChart() {
			this.form.option = null;
		},
		initChart(pollutantAcronym, pollutantSymbol, historicPollutantData, elasticacronym, designOption = null) {
			//Proceso datos historicos del contaminante
			let pollutantValues = [];
			let pollutantDays = [];
			console.info('debug historic data', historicPollutantData);
			if (historicPollutantData && typeof historicPollutantData.message !== 'string') {
				historicPollutantData.message.forEach((item) => {
					pollutantDays.push(getDateByFormat(item._source[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties.constants));
					pollutantValues.push(parseFloat(item._source[elasticacronym].toFixed(2)));
				});
			}

			//Configuro opciones del grafico
			let option;

			option = designOption ? designOption.config : this.parameterConfiguration;
			console.info('info: chart option', option, designOption, this.parameterConfiguration);

			let pollutantTitle =
				pollutantSymbol && pollutantSymbol.trim().length > 0 ? pollutantAcronym + ' (' + pollutantSymbol + ')' : pollutantAcronym;
			option['series'][0].name = pollutantAcronym;

			option['legend'] = { data: [pollutantTitle], top: '2%' };
			option['series'][0].name = pollutantTitle;
			if (Object.hasOwn(option, 'xAxis')) option['xAxis']['data'] = pollutantDays;

			let pieces = [];
			let parameterStatus = this.$store.getters.getParameterStatus.find((status) => status.pmparameterid === this.pollutant.pmparameterid);

			if (parameterStatus && designOption.applyranges === 1) {
				const statusArray = JSON.parse(parameterStatus.status);
				const colors = JSON.parse(parameterStatus.color);
				statusArray.sort((a, b) => {
					const minimumA = a.min.data;
					const minimumB = b.min.data;
					if (minimumA < minimumB) {
						return -1;
					}
					if (minimumA > minimumB) {
						return 1;
					}
					return 0; // Objects have the same minimum (statement should be unreachable)
				});
				statusArray.forEach((status, i) => {
					pieces.push({
						/* gte: status.minvalue,
							lte: status.maxvalue, */
						[status.min.op]: status.min.data,
						[status.max.op]: status.max.data,
						color: colors[i]
					});
				});

				option['visualMap'] = {
					top: '20%',
					right: '3%',
					pieces: pieces,
					outOfRange: {
						color: this.noStatusColor
					}
				};
			} else {
				option['series'][0].lineStyle = {
					color: this.noStatusColor
				};
				if (Object.hasOwn(option.series[0], 'areaStyle')) option.series[0].areaStyle = { color: this.noStatusColor };
			}

			option['series'][0].data = pollutantValues.map((value) => {
				const piece = pieces.find((p) => {
					let min = null;
					let max = null;
					if (p.gt) min = 'gt';
					if (p.gte) min = 'gte';
					if (p.lt) max = 'lt';
					if (p.lte) max = 'lte';
					const isEqual = p[min] === p[max];
					if (isEqual) {
						// Aplicar tolerancia de 0.1 si los límites son iguales
						return value >= p[min] && value - 0.1 <= p[max];
					} else {
						if (min && max) {
							return value >= p[min] && value <= p[max];
						} else if (min) {
							return value >= p[min];
						} else if (max) {
							return value <= p[max];
						}
					}
				});

				return {
					value: value,
					itemStyle: {
						color: piece ? piece.color : this.noStatusColor
					}
				};
			});
			option['series'][0].markPoint = {
				data: [
					{ type: 'max', name: 'Max' },
					{ type: 'min', name: 'Min' }
				],
				symbol: 'pin',
				symbolSize: 50,
				label: {
					formatter: pollutantSymbol ? '{@score}' + pollutantSymbol : '{@score}'
				}
			};
			option.series[0].markLine = {
				label: { fontFamily: 'Montserrat, sans-serif', color: this.rule.color ? this.rule.color : 'inherit' },
				data: [{ yAxis: this.rule.filtervalue ? this.rule.filtervalue : 50, name: 'Limit' }]
			};
			if (this.rule.isaverage == 1) option['series'][0]['markLine'].data.push({ type: 'average', name: 'Avg' });

			if (option.toolbox && Object.hasOwn(option.toolbox, 'feature')) {
				option.toolbox.top = '2%';
				option.toolbox.right = '2%';
				if (Object.hasOwn(option.toolbox.feature, 'dataView')) {
					option['toolbox']['feature']['dataView']['title'] = this.$t('charts.title');
					option['toolbox']['feature']['dataView']['lang'] = [this.$t('charts.dataView'), 'X', this.$t('charts.updateData')];
					option.toolbox.feature.dataView.optionToContent = this.setOptionToContent();
				}

				option['toolbox']['feature']['restore']['title'] = this.$t('charts.restore');
				option['toolbox']['feature']['saveAsImage']['title'] = this.$t('charts.saveAsImage');
			}

			option['series'][0].type = this.chartType;
			console.log('show intervals init', this.form.showIntervals, option);
			if (this.form.showIntervals) {
				option['series'][0].markArea = {
					silent: true,
					data: [[{ yAxis: this.pollutant.thresholdminvalue }, { yAxis: this.pollutant.thresholdmaxvalue }]],
					itemStyle: { borderType: 'dashed', borderWidth: 3, borderColor: 'red' }
				};
			} else {
				if (Object.hasOwn(option['series'][0], 'markArea')) delete option['series'][0].markArea;
			}
			option['textStyle'] = {
				fontFamily: 'Montserrat, sans-serif',
				fontSize: 14
			};
			/* option.grid = {
				height: '60%',
				bottom: '23%',
				left: '4%',
				width: '87%'
			}; */
			//option && this.chart.setOption(option);
			console.log('set historic option: ', this.displayLocation, option, option.series[0].data.length);
			return option;
		},
		initChart_old(pollutantAcronym, pollutantSymbol, historicPollutantData, elasticacronym, designOption = null) {
			let pollutantValues = [];
			let pollutantDays = [];
			console.log('initchart', historicPollutantData);
			if (historicPollutantData && typeof historicPollutantData.message !== 'string') {
				historicPollutantData.message.forEach((item) => {
					pollutantDays.push(getDateByFormat(item._source[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties.constants));
					pollutantValues.push(parseFloat(item._source[elasticacronym]).toFixed(2));
				});
			}

			let option;

			option = designOption ? designOption.config : this.parameterConfiguration;
			console.warn('log: chart option', option, this.parameterConfiguration);
			let pollutantTitle =
				pollutantSymbol && pollutantSymbol.trim().length > 0 ? pollutantAcronym + ' (' + pollutantSymbol + ')' : pollutantAcronym;
			option['legend']['data'] = [pollutantTitle];

			option['xAxis']['data'] = pollutantDays; // dateObserved
			option['series'] = [
				{
					name: pollutantTitle,
					type: this.chartType,
					data: pollutantValues,
					markPoint: {
						data: [
							{ type: 'max', name: 'Max' },
							{ type: 'min', name: 'Min' }
						],
						symbol: 'pin',
						symbolSize: 50,
						label: {
							formatter: pollutantSymbol ? '{@score}' + pollutantSymbol : '{@score}'
						}
					},
					markLine: {
						label: { fontFamily: 'Montserrat, sans-serif', color: this.rule.color ? this.rule.color : 'inherit' },
						data: [{ yAxis: this.rule.filtervalue ? this.rule.filtervalue : 50, name: 'Limit' }]
					}
				}
			];
			if (this.rule.isaverage == 1) option['series'][0]['markLine'].data.push({ type: 'average', name: 'Avg' });
			let pieces = [];
			let parameterStatus = this.$store.getters.getParameterStatus.filter((status) => status.pmparameterid === this.pollutant.pmparameterid);
			if (parameterStatus.length > 0) {
				parameterStatus.sort((a, b) => {
					const minimumA = a.minvalue;
					const minimumB = b.minvalue;
					if (minimumA < minimumB) {
						return -1;
					}
					if (minimumA > minimumB) {
						return 1;
					}
					return 0;
				});
				parameterStatus.forEach((status) => {
					pieces.push({
						gt: status.minvalue,
						lte: status.maxvalue,
						color: status.color
					});
				});
				option['visualMap'] = {
					top: 50,
					right: 50,
					pieces: pieces,
					outOfRange: {
						color: '#6f7480'
					}
				};
			} else {
				option['series'] = [
					{
						name: pollutantTitle,
						type: this.chartType,
						data: pollutantValues,
						//color:  this.$store.state.global.primaryColor,
						markPoint: {
							data: [
								{ type: 'max', name: 'Max' },
								{ type: 'min', name: 'Min' }
							],
							symbol: 'pin',
							symbolSize: 50,
							label: {
								formatter: pollutantSymbol ? '{@score}' + pollutantSymbol : '{@score}'
							}
						},
						markLine: {
							data: [{ type: 'average', name: 'Avg' }]
						},
						lineStyle: {
							color: '#6f7480'
						},
						itemStyle: {
							color: '#6f7480'
						}
					}
				];
			}

			console.log(option);
			option && this.chart.setOption(option);
		},

		configurationByPollutant(parameter) {
			console.log(
				'config by pollutant',
				this.form.configurations,
				this.parameterConfiguration,
				JSON.parse(parameter.chartconfiguration)['series'][0]['type']
			);
			this.chartType = JSON.parse(parameter.chartconfiguration)['series'][0]['type'];
			let configuration = {
				text: parameter.chartname,
				value: {
					config: JSON.parse(parameter.chartconfiguration),
					id: parameter.chartacronym,
					applyranges: parameter.applyranges,
					exportable: parameter.exportable,
					showintervals: parameter.showintervals,
					name: parameter.chartname,
					description: parameter.chartdescription
				}
			};
			return configuration;
		},

		showDesignOptions() {
			this.form.showChartOptions = true;
			if (!this.form.configurations || !this.form.designOption) {
				const historicalChartOptions = ChartOptions(constants.VISUALIZATION_TYPE_NOTIFICATIONS);
				const promise = search(historicalChartOptions);
				promise.then((historicalChartData) => {
					console.log('Configurations available for tab (HISTORIC): ', historicalChartData.data.data);

					this.historicalChartData = historicalChartData.data.data;
					this.historicalChartData.forEach((visualization) => {
						let existConfiguration = false;
						if (this.form.configurations.length > 0) {
							this.form.configurations.forEach((configuration) => {
								if (configuration.text == visualization['visualizationname']) existConfiguration = true;
							});
						}
						if (!existConfiguration) {
							this.form.configurations.push({
								text: visualization.visualizationname,
								value: {
									config: JSON.parse(visualization.configuration),
									id: visualization.acronym,
									applyranges: visualization.applyranges,
									exportable: visualization.exportable,
									showintervals: visualization.showintervals,
									name: visualization.visualizationname,
									description: visualization.description
								}
							});
						}
					});
					this.form.designOption = this.form.configurations.find((option) => option.value.id == this.parameterOption.value.id).value;
					return this.form.configurations;
				});
			}
			return this.form.configurations;
		},

		changeVisualization(design, elasticData = null) {
			this.loadingAnimation = true;
			this.form.showIntervals = false;
			if (!design) {
				design = this.configurationByPollutant(this.pollutant).value;
			}
			if (typeof design.value == 'object') {
				this.chartType = design.value.config['series'][0]['type'];
				this.parameterConfiguration = design.value.config;
			} else {
				this.chartType = design.config['series'][0]['type'];
				this.parameterConfiguration = design.config;
			}
			let designOption = this.form.designOption;
			if (this.historicalChartData.length > 0) {
				let visualization = this.historicalChartData.find((vis) => vis.acronym == design.id);
				if (visualization)
					designOption = {
						config: JSON.parse(visualization.configuration),
						id: visualization.acronym,
						applyranges: visualization.applyranges,
						exportable: visualization.exportable,
						showintervals: visualization.showintervals,
						name: visualization.visualizationname,
						description: visualization.description
					};
				console.debug('debug: design visualization 1', designOption, this.form.designOption);
			}
			console.debug('debug: design visualization 2', this.historicalChartData);

			this.form.option = this.initChart(
				this.pollutant.name,
				this.pollutant.symbol,
				elasticData ?? this.form.chartData,
				this.pollutant.elasticacronym,
				designOption
			);
			this.loadingAnimation = false;
		},
		submitEndDate() {
			console.log('info: submit end date ' + this.displayLocation, this.form.startDate, this.form.endDate);
			if (this.form.startDate && this.form.endDate) {
				console.log('inside 123', this.feature);
				this.deleteChart();
				this.loadingAnimation = true;
				this.form.chartData = {};

				console.warn('loadingAnimation: ' + this.loadingAnimation);
				let indexName = Object.hasOwn(this.feature, 'elasticindex') ? this.feature.elasticindex : obtainIndexName(this.feature);
				let stationCode = Object.hasOwn(this.feature, 'code') ? this.feature.code : this.notificationSource.device;
				let query = query_HistoricDataByParameter(stationCode, this.pollutant.elasticacronym, this.form.startDate, this.form.endDate);
				const promise = lastData(indexName, query);
				console.warn('info: submitEndDate ' + this.pollutant.elasticacronym, indexName, query, promise);
				promise.then((elasticData) => {
					console.warn('info: submitEndDate then ' + this.pollutant.elasticacronym, elasticData, this.form);
					console.warn('loadingAnimation: ' + this.loadingAnimation);

					if (elasticData.message.length > 0) {
						/* this.$store.commit('setHistoricElasticData', {
							index: this.pollutant.elasticacronym,
							data: elasticData
						}); */
						console.log('debug design options', this.form.designOption, this.form.configurations, this.showDesignOptions());
						this.form.chartData = elasticData;
						this.showDesignOptions();
						console.log('call changevisualization: submitEndDate', this.form.designOption, elasticData);
						this.changeVisualization(this.form.designOption, elasticData);
						console.warn('loadingAnimation: ' + this.loadingAnimation);
					} else {
						console.log('inserted null');
						/* this.$store.commit('setHistoricElasticData', {
							index: this.pollutant.elasticacronym,
							data: null
						}); */
						this.loadingAnimation = false;
						this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
						this.form.showChartOptions = false;
					}
				});
			}
		},
		loadDesignOptions() {
			const historicalChartOptions = ChartOptions(constants.VISUALIZATION_TYPE_NOTIFICATIONS);
			const promise = search(historicalChartOptions);
			this.form.configurations = [this.parameterOption];
			promise.then((historicalChartData) => {
				console.log('Configurations available for tab (HISTORIC) debug: ', historicalChartData.data.data);

				this.historicalChartData = historicalChartData.data.data;
				this.historicalChartData.forEach((visualization) => {
					let existConfiguration = false;
					if (this.form.configurations.length > 0) {
						this.form.configurations.forEach((configuration) => {
							if (configuration.text == visualization['visualizationname']) existConfiguration = true;
						});
					}
					if (!existConfiguration) {
						this.form.configurations.push({
							text: visualization.visualizationname,
							value: {
								config: JSON.parse(visualization.configuration),
								id: visualization.acronym,
								applyranges: visualization.applyranges,
								exportable: visualization.exportable,
								showintervals: visualization.showintervals,
								name: visualization.visualizationname,
								description: visualization.description
							}
						});
					}
				});
				this.form.configurations.sort((a, b) => {
					const elementA = a.text.toUpperCase();
					const elementB = b.text.toUpperCase();
					return elementA.localeCompare(elementB);
				});
				return this.form.configurations;
			});
		},
		setOptionToContent() {
			return (opt) => {
				const parameterStatus = this.pollutant
					? this.$store.getters.getParameterStatus.find((status) => status.pmparameterid === this.pollutant.pmparameterid)
					: false;
				let seriesData = opt.series[0].data;
				let seriesName = opt.series[0].name;
				let categories = Array.isArray(opt.xAxis) ? opt.xAxis[0].data : opt.xAxis.data;
				let ws_data = [];
				let table = '';
				if (!parameterStatus) {
					ws_data = [...categories.map((category, index) => [category, seriesData[index].value])];
					table =
						'<table style="width:100%;text-align:center;" class="data-view-table mb-4"><tbody><tr class="fw-bold">' +
						'<td>' +
						this.$t('rasterTools.date') +
						'</td>' +
						'<td>' +
						seriesName +
						'</td>' +
						'</tr>';
					ws_data.forEach((item) => {
						table += '<tr>' + '<td>' + item[0] + '</td>' + '<td>' + item[1] + '</td>' + '</tr>';
					});
				} else {
					ws_data = [
						...categories.map((category, index) => [
							category,
							seriesData[index].value,
							this.getStatusInRange(seriesData[index], parameterStatus),
							seriesData[index].itemStyle.color
						])
					];
					table =
						'<table style="width:100%;text-align:center;" class="data-view-table mb-4"><tbody><tr class="fw-bold">' +
						'<td>' +
						this.$t('rasterTools.date') +
						'</td>' +
						'<td>' +
						seriesName +
						'</td>' +
						'<td>' +
						this.$t('form.pmstatus') +
						'</td>' +
						'<td>' +
						this.$t('pmstatus.color') +
						'</td>' +
						'</tr>';
					ws_data.forEach((item) => {
						table +=
							'<tr>' +
							'<td>' +
							item[0] +
							'</td>' +
							'<td>' +
							(item[1] !== null ? item[1] : '-') +
							'</td>' +
							'<td>' +
							item[2] +
							'</td>' +
							'<td style="color: ' +
							item[3] +
							'">' +
							item[3] +
							'</td>' +
							'</tr>';
					});
				}

				table += '</tbody></table>';
				return table;
			};
		},
		getStatusInRange(data, parameterStatus) {
			let activeStatus = getStatusProperties(parameterStatus, data.value);
			return activeStatus ? activeStatus.name : '-';
		}
	}
};
</script>

<style lang="postcss">
.historic-notification-chart {
	& .vue-echarts-inner > div:last-child > div:last-child {
		top: 12px !important;
		bottom: auto !important;
		& > div[style*='cursor: pointer'] {
			background-color: var(--azul-primary-100) !important;
			color: white;
			font-size: 18px !important;
			font-weight: bold;
			padding: 2px 8px !important;
			top: 6px !important;
		}
	}
}
</style>
