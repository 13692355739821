<template>
	<div class="pt-0 width-100 advanced-chart">
		<v-form v-model="pollutantsAdvancedForm" ref="form" style="overflow: hidden; height: 62px">
			<v-row no-gutters class="px-3">
				<v-col cols="6" :md="showAdvancedDate ? 3 : 6">
					<v-select
						v-model="form.selectedOptions"
						:items="options"
						:label="$t('adchart.selectPollutants')"
						item-text="text"
						item-value="value"
						multiple
						small-chips
						chips
						clearable
						hide-details
						ref="advancedSelect"
						class="mt-2"
						@input="inputSelectedOptions"
						@blur="blurSelectedOptions"
					></v-select>
				</v-col>
				<v-col cols="6" class="d-flex" :md="showAdvancedDate ? 3 : 6">
					<v-select
						:label="$t('aqi.dateInterval')"
						toplabel
						clearable
						required
						:disabled="isDatePickerDisabled"
						v-model="form.dateInterval"
						:items="intervals"
						hide-details
						@input="submitDateInterval"
						class="mt-2 px-1"
					></v-select>
					<echart-export-excel
						v-if="form.option && form.dateInterval !== -1"
						:option="form.option"
						:designOption="form.designOption"
						:dateInterval="[form.startDate, form.endDate]"
						:bgColor="primaryColor"
						iconColor="white"
						height="39"
						spacingClass="mt-5"
						:chartInstance="chartInstance"
						:advancedProps="advancedProps"
					></echart-export-excel>
				</v-col>
				<v-col v-if="showAdvancedDate" cols="12" md="6">
					<v-row no-gutters>
						<v-col>
							<pui-date-field
								:label="$t('aqi.startDate')"
								:disabled="isDatePickerDisabled"
								toplabel
								v-model="form.startDate"
								:min="minDate"
								:max="form.endDate ?? Date.now()"
								no-title
								class="ma-0"
								@input="inputStartDate"
								:tooltipDescription="form.infoStartDate"
							></pui-date-field>
						</v-col>
						<v-col>
							<pui-date-field
								:label="$t('aqi.endDate')"
								:disabled="isEndDatePickerDisabled"
								toplabel
								v-model="form.endDate"
								:min="form.startDate"
								:max="Date.now()"
								no-title
								@input="submitEndDate"
								:tooltipDescription="form.infoEndDate"
							></pui-date-field>
						</v-col>
						<echart-export-excel
							v-if="form.option"
							:option="form.option"
							:designOption="form.designOption"
							:dateInterval="[form.startDate, form.endDate]"
							:bgColor="primaryColor"
							iconColor="white"
							height="39"
							spacingClass="mt-5"
							:chartInstance="chartInstance"
							:advancedProps="advancedProps"
						></echart-export-excel>
					</v-row>
				</v-col>
				<!-- <v-col cols="6" :md="form.option ? 3 : 4">
					<pui-date-field
						:label="$t('aqi.startDate')"
						:disabled="isDatePickerDisabled"
						toplabel
						v-model="form.startDate"
						:min="minDate"
						:max="form.endDate ?? Date.now()"
						no-title
						@input="inputStartDate"
					></pui-date-field>
				</v-col>
				<v-col cols="6" :md="form.option ? 3 : 4">
					<pui-date-field
						:label="$t('aqi.endDate')"
						:disabled="isEndDatePickerDisabled"
						toplabel
						v-model="form.endDate"
						:min="form.startDate"
						:max="Date.now()"
						no-title
						@input="submitEndDate"
					></pui-date-field>
				</v-col>
				<v-col cols="1" class="d-flex justify-start align-center"> </v-col> -->
			</v-row>
		</v-form>
		<!-- Loading animation -->
		<div class="d-flex justify-center align-center" v-if="loadingAnimation && !form.option" :style="chartStyle">
			<v-progress-circular indeterminate :color="primaryColor"></v-progress-circular>
		</div>
		<v-chart v-if="form.option" :loading="loadingAnimation" :option="form.option" :style="chartStyle" autoresize ref="advancedChart"></v-chart>
	</div>
</template>

<script>
//import * as echarts from 'echarts';
import { lastData, scrollData } from '@/api/databases_API';
import { query_filterParameterByInterval } from '@/utils/queries';
import { obtainIndexName, getDateByFormat } from '@/api/common';

import constants from '@/utils/constants';
import { query_FirstDataByDevice } from '../../utils/queries';
import VChart from 'vue-echarts';
import EchartExportExcel from './functionalities/EchartExportExcel.vue';

import { use } from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { LegendComponent, GridComponent, ToolboxComponent, DataZoomComponent, TooltipComponent, MarkLineComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

use([LegendComponent, GridComponent, ToolboxComponent, DataZoomComponent, TooltipComponent, MarkLineComponent, LineChart, CanvasRenderer]);
export default {
	name: 'AdvancedChart',
	components: {
		VChart,
		EchartExportExcel
	},
	props: {
		pollutants: {
			type: Array,
			required: true
		},
		displayLocation: {
			type: String,
			required: true
		},
		deviceObject: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			pollutantObjList: {},
			options: [],
			width: '100%',
			height: '100%',
			form: {
				startDate: null,
				endDate: null,
				selectedOptions: [],
				option: null,
				chartData: null,
				formDisabled: false,
				startDateDisabled: true,
				endDateDisabled: true,
				infoStartDate: this.$t('aqi.infoDateIntervalDisabled'),
				infoEndDate: this.$t('aqi.infoDateIntervalDisabled'),
				dateInterval: null,
				designOption: {
					id: constants.VISUALIZATIONS.TIME_SERIES,
					name: 'ADVANCED_CHART_COMPARE_PROPERTIES'
				}
			},
			intervals: [
				{ text: this.$t('aqi.last24h'), value: 1 },
				{ text: this.$t('aqi.last48h'), value: 2 },
				{ text: this.$t('aqi.lastWeek'), value: 7 },
				{ text: this.$t('aqi.last15D'), value: 15 },
				{ text: this.$t('aqi.lastMonth'), value: 30 },
				{ text: this.$t('aqi.customInterval'), value: -1 }
			],
			pollutantsAdvancedForm: false,
			loadingAnimation: false,
			chartStyle: {},
			chartType: 'line',
			primaryColor: this.$store.state.global.primaryColor,
			minDate: null,
			chartInstance: null,
			advancedProps: null,
			showAdvancedDate: false
			//overrideFileName: 'ADVANCED_CHART_COMPARE_PROPERTIES'
		};
	},
	computed: {
		selectedTabDevice: {
			get() {
				return this.$store.getters.getSelectedTabDevice;
			},
			set(value) {
				this.$store.commit('setSelectedTabDevice', value);
			}
		},
		isDatePickerDisabled() {
			return this.form.selectedOptions && this.form.selectedOptions.length == 0;
		},
		isEndDatePickerDisabled() {
			return !this.form.startDate;
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		},
		dialogHeight() {
			if (this.displayLocation === 'dialog') {
				return document.getElementById('dialog-container').clientHeight;
			}
			return false;
		}
	},
	watch: {
		displayMode: {
			handler(newValue, oldValue) {
				if (this.displayLocation === oldValue) {
					this.commitChanges();
				} else {
					this.restoreChanges();
					if (this.form.chartData) {
						if (!this.form.option) this.form.option = this.initChart();
					}
				}
			}
		},
		'form.option': {
			handler(newVal) {
				if (newVal) {
					this.$nextTick(() => {
						if (this.$refs['advancedChart']) {
							this.chartInstance = this.$refs['advancedChart'];
						} else {
							console.error('Chart instance is not available');
						}
					});
				}
			},
			immediate: true
		},
		deviceObject() {
			this.form = {
				startDate: null,
				endDate: null,
				selectedOptions: [],
				option: null,
				chartData: null,
				designOption: {
					id: constants.VISUALIZATIONS.TIME_SERIES,
					name: 'ADVANCED_CHART_COMPARE_PROPERTIES'
				}
			};
			this.fillPollutants();
		}
	},
	mounted() {
		this.fillPollutants();
		this.getDeviceFirstDate();
		this.chartStyle = this.displayLocation == 'card' ? { height: '296px', width: '100%' } : { height: 'calc(70vh - 155px)', width: '85vw' };
		if (this.displayLocation == 'dialog') this.restoreChanges();
	},
	beforeDestroy() {
		this.deleteChart();
	},
	methods: {
		deleteChart() {
			this.form.option = null;
			this.advancedProps = null;
		},
		getAdvancedHistoricPollutantData(pollutant) {
			let pollutantData = [];
			let data = this.$store.getters.getAdvancedHistoricElasticData[pollutant];
			data.forEach((doc) => {
				pollutantData.push({
					date: getDateByFormat(doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties.constants),
					value: parseFloat(doc[pollutant].toFixed(2))
				});
			});
			return pollutantData;
		},
		fillPollutants() {
			this.options = [];
			this.pollutants.forEach((pollutant) => {
				this.pollutantObjList[pollutant.elasticacronym] = pollutant;
				this.options.push({
					value: pollutant.elasticacronym,
					text: pollutant.name
				});
			});
			this.options.sort((a, b) => {
				const labelA = a.text.toUpperCase();
				const labelB = b.text.toUpperCase();

				if (labelA < labelB) {
					return -1;
				}
				if (labelA > labelB) {
					return 1;
				}
				return 0;
			});
		},
		getAllPollutantValues(startDate, endDate) {
			this.loadingAnimation = true;
			let indexName = obtainIndexName(this.deviceObject);

			let parameterPromises = [];
			let elasticDataObj = {};
			this.form.selectedOptions.forEach((parameter) => {
				parameterPromises.push(
					scrollData(indexName, query_filterParameterByInterval(this.deviceObject.code, parameter, startDate.valueOf(), endDate.valueOf()))
				);
			});
			Promise.all(parameterPromises).then((results) => {
				if (results.length > 0 && results[0].message.length > 0) {
					this.form.selectedOptions.forEach((parameter, i) => {
						elasticDataObj[parameter] = results[i].message.map((element) => {
							return { [parameter]: element['_source'][parameter], dateObserved_timestamp: element['_source'].dateObserved_timestamp };
						});
					});
					this.form.chartData = elasticDataObj;
					this.$store.commit('setAdvancedHistoricElasticData', elasticDataObj);
					this.form.option = this.initChart();
					this.loadingAnimation = false;
					this.commitChanges();
				} else {
					this.loadingAnimation = false;
					this.form.chartData = null;
					this.$store.commit('setAdvancedHistoricElasticData', null);
					this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
				}
			});
		},

		commitChanges() {
			console.log('commit changes', this.displayMode, '?', this.displayMode == this.displayLocation);
			if (this.displayMode == this.displayLocation) {
				this.$store.commit('setFormAdvancedObject', this.form);
			}
		},
		restoreChanges() {
			if (this.displayMode == this.displayLocation) {
				this.deleteChart();
				this.$store.getters.getAdvancedStore
					? (this.form = this.$store.getters.getAdvancedStore)
					: console.warn('Note: Problem with advanced store', this.$store.getters.getAdvancedStore);
				this.loadingAnimation = false;
				if (!this.form.chartData || !this.form.option) this.deleteChart();
				else this.initChart();
				if (this.form.selectedOptions.length > 0 && this.form.dateInterval) {
					if (this.form.dateInterval == -1) {
						this.showAdvancedDate = true;
						this.form.startDateDisabled = false;
						this.form.infoStartDate = this.$t('aqi.infoStartDate');
						this.form.infoEndDate = this.$t('aqi.infoEndDate');
					} else {
						this.showAdvancedDate = false;
						this.form.startDateDisabled = true;
						this.form.startDate = this.form.endDate = null;
					}
				}
			}
		},
		initChart() {
			let days = {};
			let values = {};
			let allDates = [];
			let pollutantData = {};
			let isCardLocation = this.displayLocation == 'card';
			let elasticData = this.form.chartData ?? this.$store.getters.getAdvancedHistoricElasticData;

			this.form.selectedOptions.forEach((pollutant) => {
				days[pollutant] = [];
				values[pollutant] = [];
				pollutantData[pollutant] = [];

				if (elasticData) {
					elasticData[pollutant].forEach((doc) => {
						let date = getDateByFormat(doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties.constants);
						if (!allDates.includes(date)) allDates.push(date);
						days[pollutant].push(date);
						values[pollutant].push(parseFloat(doc[pollutant].toFixed(2)));
						pollutantData[pollutant].push([doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], parseFloat(doc[pollutant].toFixed(2))]);
					});
				}
			});
			allDates.sort((a, b) => {
				return a.localeCompare(b);
			});
			//console.log('debug days and values 1', days, values, allDates);
			this.form.selectedOptions.forEach((pollutant) => {
				if (allDates.length != days[pollutant].length) {
					allDates.forEach((date, i) => {
						if (!days[pollutant].includes(date)) {
							days[pollutant] = days[pollutant].toSpliced(i, 0, date);
							values[pollutant] = values[pollutant].toSpliced(i, 0, null);
						}
					});
				}
			});
			//console.log('debug days and values 2', days, values, allDates, this.pollutantObjList);

			let option = {
				toolbox: {
					right: 10,
					feature: {
						restore: {
							title: this.$t('charts.restore'),
							show: 'true'
						},
						saveAsImage: {
							title: this.$t('charts.saveAsImage'),
							show: 'true'
						},
						dataView: {
							title: this.$t('charts.title'),
							lang: [this.$t('charts.dataView'), this.$t('charts.closeView'), this.$t('charts.updateData')],
							show: 'true',
							readOnly: 'true'
						}
					}
				},
				dataZoom: [
					{
						type: 'slider',
						xAxisIndex: 0,
						filterMode: 'true'
					},
					{
						type: 'slider',
						yAxisIndex: 0,
						filterMode: 'true'
					},
					{
						type: 'inside',
						xAxisIndex: 0,
						filterMode: 'none'
					},
					{
						type: 'inside',
						yAxisIndex: 0,
						filterMode: 'none'
					}
				],
				xAxis: {
					type: 'time', // Eje X de tipo tiempo
					boundaryGap: false,
					splitLine: {
						show: true
					}
				},
				yAxis: {
					type: 'value',
					splitLine: {
						show: true
					}
				},
				tooltip: {
					trigger: 'axis',
					formatter: (params) => {
						let dateLabel = getDateByFormat(params[0].axisValue, this.userProperties.constants);
						let element = '<div class="pa-0"><span class="pb-2">' + dateLabel + '</span>';
						params.forEach((seriesParams) => {
							element +=
								'<div class="d-flex justify-space-between"><span class="pr-2">' +
								seriesParams.marker +
								' ' +
								seriesParams.seriesName +
								'</span><b>' +
								seriesParams.data[1] +
								'</b></div>';
						});

						element += '</div>';

						return element;
					}
				},
				legend: { top: isCardLocation ? '3%' : '1%' },
				series: []
			};
			this.form.selectedOptions.forEach((pollutant) => {
				let pollutantObj = this.pollutantObjList[pollutant];
				let pollutantTitle =
					pollutantObj.symbol && pollutantObj.symbol.trim().length > 0
						? pollutantObj.name + ' (' + pollutantObj.symbol + ')'
						: pollutantObj.name;
				option.series.push({
					name: pollutantTitle,
					id: pollutant,
					type: 'line',
					smooth: false,
					emphasis: {
						focus: 'series'
					},
					markLine: {
						data: [{ type: 'average', name: 'Avg' }]
					},
					label: {
						formatter: pollutantObj.symbol ? '{@score}' + pollutantObj.symbol : '{@score}'
					},
					data: pollutantData[pollutant]
				});
			});
			option['textStyle'] = {
				fontFamily: 'Montserrat, sans-serif',
				fontSize: 14
			};
			option.toolbox.feature.dataView.optionToContent = this.setOptionToContent(allDates, values);
			this.advancedProps = { categories: allDates, values: values };
			option.grid = {
				height: isCardLocation ? '60%' : '75%',
				bottom: isCardLocation ? '25%' : '15%',
				left: '4%',
				width: '90%'
			};
			//console.log('option log', option);
			return option;
		},
		inputStartDate() {
			if (this.form.startDate) {
				this.form.endDateDisabled = false;
				if (this.form.endDate) this.submitEndDate();
				else this.commitChanges();
			} else {
				this.form.endDateDisabled = true;
				this.commitChanges();
			}
		},
		submitEndDate() {
			if (this.form.startDate && this.form.endDate) {
				let startDate = new Date(this.form.startDate);
				let endDate = new Date(this.form.endDate);
				let startPlusOne = startDate.getUTCDate() + 1;
				let endPlusOne = endDate.getUTCDate() + 1;
				startDate.setUTCHours(0, 0, 0, 0);
				startDate.setUTCDate(startPlusOne);
				endDate.setUTCHours(23, 59, 59, 0);
				endDate.setUTCDate(endPlusOne);
				this.getAllPollutantValues(startDate, endDate);
			}
		},
		submitDateInterval() {
			if (this.form.selectedOptions.length > 0 && this.form.dateInterval) {
				if (this.form.dateInterval == -1) {
					this.showAdvancedDate = true;
					this.form.startDateDisabled = false;
					this.form.infoStartDate = this.$t('aqi.infoStartDate');
					this.form.infoEndDate = this.$t('aqi.infoEndDate');
					this.commitChanges();
					if (this.form.startDate && this.form.endDate) {
						let startDate = new Date(this.form.startDate);
						let endDate = new Date(this.form.endDate);
						let startPlusOne = startDate.getUTCDate() + 1;
						let endPlusOne = endDate.getUTCDate() + 1;
						startDate.setUTCHours(0, 0, 0, 0);
						startDate.setUTCDate(startPlusOne);
						endDate.setUTCHours(23, 59, 59, 0);
						endDate.setUTCDate(endPlusOne);
						this.getAllPollutantValues(startDate, endDate);
					}
				} else {
					this.showAdvancedDate = false;
					this.form.startDateDisabled = true;
					this.form.startDate = this.form.endDate = null;
					let d = new Date();
					d.setDate(d.getDate() - this.form.dateInterval);
					this.getAllPollutantValues(d.valueOf(), Date.now());
				}
			} else {
				this.showAdvancedDate = false;
				this.form.startDateDisabled = true;
				this.form.startDate = this.form.endDate = null;
				this.deleteChart();
				this.form.chartData = null;
				this.loadingAnimation = false;
				this.commitChanges();
			}
		},
		inputSelectedOptions() {
			this.form.selectedOptions.sort((a, b) => {
				let polA = this.options.find((pol) => pol.value == a).text;
				let polB = this.options.find((pol) => pol.value == b).text;
				return polA > polB ? 1 : polB > polA ? -1 : 0;
			});
			this.commitChanges();
			if (this.form.selectedOptions.length == this.pollutants.length) {
				const advancedSelect = this.$refs.advancedSelect;
				if (advancedSelect) {
					advancedSelect.blur();
				}
			}
		},
		blurSelectedOptions() {
			if (this.form.selectedOptions.length > 0 && this.form.dateInterval) {
				if (this.form.dateInterval == -1) {
					if (this.form.startDate && this.form.endDate) {
						let startDate = new Date(this.form.startDate);
						let endDate = new Date(this.form.endDate);
						let startPlusOne = startDate.getUTCDate() + 1;
						let endPlusOne = endDate.getUTCDate() + 1;
						startDate.setUTCHours(0, 0, 0, 0);
						startDate.setUTCDate(startPlusOne);
						endDate.setUTCHours(23, 59, 59, 0);
						endDate.setUTCDate(endPlusOne);
						this.getAllPollutantValues(startDate, endDate);
					}
				} else {
					let d = new Date();
					d.setDate(d.getDate() - this.form.dateInterval);
					this.getAllPollutantValues(d.valueOf(), Date.now());
				}
			}
		},
		async getDeviceFirstDate() {
			let index = obtainIndexName(this.deviceObject);
			let result = await lastData(index, query_FirstDataByDevice(this.deviceObject.code));
			if (typeof result.message[0] !== 'undefined') {
				let messageData = result.message[0]._source;
				this.minDate = new Date(messageData['dateObserved_timestamp']).toISOString();
			}
		},
		setOptionToContent(allDates, pollutantValues, unit = '') {
			return (opt) => {
				const optionSeries = opt.series.filter((serie) => serie.type !== 'pie');
				let categories = [];
				categories = allDates;
				let headerRow = [this.$t('rasterTools.date')];
				optionSeries.forEach((serie) => headerRow.push(serie.name));
				let ws_data = [
					...categories.map((category, index) => {
						let row = [category];
						optionSeries.forEach((serie) => row.push(pollutantValues[serie.id][index]));
						return row;
					})
				];
				let table = '<table style="width:100%;text-align:center;" class="data-view-table mb-4"><tbody><tr class="fw-bold">';
				headerRow.forEach((item) => (table += '<td class="px-1">' + item + '</td>'));
				table += '</tr>';

				ws_data.forEach((row) => {
					table += '<tr>';
					row.forEach((item, i) => (table += '<td>' + (i == 0 ? item : item && item >= 0 ? item + ' ' + unit : '-') + '</td>'));
					table += '</tr>';
				});

				table += '</tbody></table>';
				return table;
			};
		}
	}
};
</script>

<style scoped></style>
