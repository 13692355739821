<template>
	<div class="pt-0 w-100 historic-chart">
		<v-form
			v-model="pollutantsForm"
			ref="form"
			:style="displayLocation == 'card' ? 'max-height: 60px; overflow: hidden auto;' : 'max-height: 124px; overflow: hidden;'"
		>
			<v-row no-gutters class="px-3">
				<v-col cols="12" md="4">
					<v-autocomplete
						:label="$t('common.devices')"
						toplabel
						clearable
						required
						v-model="form.selectedDevices"
						:items="devicesOptions"
						multiple
						item-text="name"
						item-value="code"
						hide-details
						class="mt-2"
						@input="dynamicInput"
					></v-autocomplete>
				</v-col>
				<v-col cols="12" md="4">
					<v-autocomplete
						:label="$t('aqi.pollutant')"
						toplabel
						clearable
						required
						:disabled="form.formDisabled"
						v-model="form.selectedPollutant"
						:items="pollutants"
						item-text="name"
						item-value="elasticacronym"
						:hide-details="true"
						class="mt-2 px-1"
						@input="dynamicInput"
					>
						<template v-slot:item="{ item }">
							<div class="v-list-item__content">
								<div class="v-list-item__title">{{ item.name }} {{ item.symbol ? '(' + item.symbol + ')' : '' }}</div>
							</div>
						</template>
						<template v-slot:selection="{ item }">
							<div class="v-select__selection v-select__selection--comma">
								{{ item.name }} {{ item.symbol ? '(' + item.symbol + ')' : '' }}
							</div>
						</template>
					</v-autocomplete>
				</v-col>
				<!--  -->
				<v-col cols="12" class="d-flex" md="4">
					<v-select
						:label="$t('aqi.dateInterval')"
						toplabel
						clearable
						required
						:disabled="!form.selectedPollutant || !form.selectedDevices.length > 0"
						v-model="form.dateInterval"
						:items="intervals"
						:hide-details="true"
						@input="submitDateInterval"
						class="mt-2 px-1"
					></v-select>
					<div class="d-flex" v-if="form.showChartOptions || form.option">
						<echart-show-interval
							v-if="pollutant && form.chartData && enableShowIntervals"
							:elasticData="form.chartData"
							:designOption="form.designOption"
							:pollutant="pollutant"
							method="initChartDatasource"
							:showIntervals="form.showIntervals"
							@toggleShowIntervals="toggleShowIntervals('initChartDatasource')"
							:height="42"
						></echart-show-interval>
						<echart-export-excel
							v-if="form.designOption && form.designOption.exportable == 1"
							:option="form.option ?? {}"
							:designOption="overrideDesign"
							:pollutant="pollutant"
							:dateInterval="form.dateInterval"
							:bgColor="primaryColor"
							iconColor="white"
							:chartInstance="chartInstance"
							spacingClass="mt-4"
							:height="42"
							:advancedProps="advancedProps"
						></echart-export-excel>
						<v-tooltip v-if="displayLocation == 'card'" top :color="primaryColor" transition="none">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									:disabled="form.formDisabled"
									depressed
									color="bg-color-primary color-white"
									class="mt-4 ml-1"
									height="42"
									width="42"
									min-width="42"
									v-model="toggleMetrics"
									@click="toggleDisplayMode"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon class="fak fa-solid-open color-white"></v-icon
								></v-btn>
							</template>
							<span class="fs-regular">{{ $t('panels.device.showMetrics') }}</span>
						</v-tooltip>
					</div>
				</v-col>
				<v-col v-if="showAdvancedDate" cols="12" md="6">
					<pui-date-field
						:label="$t('aqi.startDate')"
						toplabel
						:required="!form.startDateDisabled"
						v-model="form.startDate"
						:disabled="form.startDateDisabled"
						:max="Date.now()"
						:tooltipDescription="form.infoStartDate"
						@input="inputStartDate"
					></pui-date-field>
				</v-col>
				<v-col v-if="showAdvancedDate" cols="12" md="6">
					<pui-date-field
						:label="$t('aqi.endDate')"
						toplabel
						:required="!form.endDateDisabled"
						v-model="form.endDate"
						:disabled="form.endDateDisabled"
						:min="form.startDate"
						:max="Date.now()"
						:tooltipDescription="form.infoEndDate"
						@input="submitEndDate"
					></pui-date-field>
				</v-col>
				<!-- Design options -->
				<!-- <v-col cols="12" class="d-flex justify-start align-center" md="1" v-if="form.showChartOptions || form.option">
					<echart-show-interval
						v-if="pollutant && form.chartData && enableShowIntervals"
						:elasticData="form.chartData"
						:designOption="form.designOption"
						:pollutant="pollutant"
						method="initChartDatasource"
						:showIntervals="form.showIntervals"
						@toggleShowIntervals="toggleShowIntervals('initChartDatasource')"
						:height="42"
					></echart-show-interval>
					<echart-export-excel
						v-if="form.designOption && form.designOption.exportable == 1"
						:option="form.option ?? {}"
						:designOption="form.designOption"
						:pollutant="pollutant"
						:dateInterval="form.dateInterval"
						:bgColor="primaryColor"
						iconColor="white"
						:chartInstance="chartInstance"
						spacingClass="mt-4"
						:height="42"
					></echart-export-excel>
				</v-col> -->
			</v-row>
		</v-form>
		<!-- Metrics component -->
		<div row nowrap v-if="form.showChartOptions && displayLocation == 'dialog'" class="d-flex justify-center mt-3">
			<v-chip v-if="metricAverage" class="fw-bold" style="margin-right: 5%" :ripple="false"
				>{{ $t('panels.notifications.average_operator') }}: {{ metricAverage }}</v-chip
			>
			<v-chip v-if="metricMin" class="fw-bold">Min: {{ metricMin }}</v-chip>
			<v-chip v-if="metricMax" class="fw-bold" style="margin-left: 5%">Max: {{ metricMax }}</v-chip>
		</div>
		<!-- Loading animation -->
		<div class="d-flex justify-center align-center" v-if="loadingAnimation && !form.option" :style="chartStyle">
			<v-progress-circular indeterminate :color="primaryColor"></v-progress-circular>
		</div>
		<v-chart
			v-if="form.option"
			:loading="loadingAnimation"
			:option="form.option"
			:style="chartStyle"
			autoresize
			ref="advancedDatasourceChart"
			:class="displayLocation == 'dialog' ? 'px-2 pb-3' : 'px-2'"
			@datazoom="changedZoom"
		></v-chart>
	</div>
</template>

<script>
import { CARD_HEIGHT } from '@/lib/variables/panels';
import HistoricChartMixin from '@/mixins/HistoricChartMixin';
import { search, ChartOptions, obtainIndexName, sortKey, getDateByFormat } from '@/api/common';
import { /* lastData, */ scrollData } from '@/api/databases_API';
import { query_AdvancedDataByParameter } from '@/utils/queries';
import constants from '@/utils/constants';

import EchartExportExcel from './functionalities/EchartExportExcel.vue';
import EchartShowInterval from './functionalities/EchartShowInterval.vue';
import EchartShowIntervalMixin from './functionalities/EchartShowIntervalMixin';
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { BarChart, LineChart, PieChart } from 'echarts/charts';
import {
	TitleComponent,
	TooltipComponent,
	LegendComponent,
	ToolboxComponent,
	GridComponent,
	DataZoomComponent,
	VisualMapComponent,
	MarkLineComponent,
	MarkPointComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

use([
	TitleComponent,
	TooltipComponent,
	LegendComponent,
	ToolboxComponent,
	GridComponent,
	DataZoomComponent,
	VisualMapComponent,
	MarkLineComponent,
	MarkPointComponent,
	BarChart,
	LineChart,
	PieChart,
	CanvasRenderer
]);

//import logo_multireload from './logo_multireload.png';
export default {
	name: 'AdvancedDatasourceChart',
	mixins: [EchartShowIntervalMixin, HistoricChartMixin],
	components: {
		VChart,
		EchartExportExcel,
		EchartShowInterval
	},
	props: {
		feature: {
			type: Object,
			required: true
		},
		pollutants: {
			type: Array,
			required: true
		},
		displayLocation: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			width: '100%',
			height: '100%',
			chart: null,
			pollutantNoSelected: true,
			xData: [],
			series: [],
			form: {
				index: this.feature.code,
				dateInterval: null,
				startDate: null,
				endDate: null,
				formDisabled: false,
				startDateDisabled: true,
				endDateDisabled: true,
				infoStartDate: this.$t('aqi.infoDateIntervalDisabled'),
				infoEndDate: this.$t('aqi.infoDateIntervalDisabled'),
				chartData: null,
				option: null,
				designOption: null,
				configurations: [],
				showChartOptions: false,
				showIntervals: false,
				selectedDevices: [],
				selectedPollutant: null
			},
			intervals: [
				/* { text: this.$t('aqi.chooseInterval'), value: '0' }, */
				{ text: this.$t('aqi.last24h'), value: 1 },
				{ text: this.$t('aqi.last48h'), value: 2 },
				{ text: this.$t('aqi.lastWeek'), value: 7 },
				{ text: this.$t('aqi.last15D'), value: 15 },
				{ text: this.$t('aqi.lastMonth'), value: 30 },
				{ text: this.$t('aqi.customInterval'), value: -1 }
			],

			pollutantsForm: false,
			loadingAnimation: false,
			chartType: '',
			historicalChartData: [],
			chartStyle: {},
			parameterConfiguration: {},
			parameterOption: null,
			saving: false,
			showAdvancedDate: false,
			primaryColor: this.$store.state.global.primaryColor,
			noStatusColor: '#6f7480',
			chartInstance: null,
			advancedProps: null,
			selectAll: { code: -1, name: this.$t('panels.device.select-all') },
			selectNone: { code: -1, name: this.$t('panels.device.unselect-all') },
			devicesOptions: [],
			toggleMetrics: false,
			metricAverage: 0,
			metricMin: 0,
			metricMax: 0,
			overrideDesign: {
				id: constants.VISUALIZATIONS.TIME_SERIES,
				name: 'ADVANCED_CHART_COMPARE_DEVICES'
			}
		};
	},
	computed: {
		selectedTabDevice: {
			get() {
				return this.$store.getters.getSelectedTabDevice;
			},
			set(value) {
				this.$store.commit('setSelectedTabDevice', value);
			}
		},
		devices() {
			if (this.$store.getters.getDevicesData && this.$store.getters.getDevicesData.length > 0) {
				return this.$store.getters.getDevicesData
					.filter((device) => device.pmdatasourcetypeid == this.feature.pmdatasourcetypeid)
					.sort((a, b) => {
						const [textA, numA] = sortKey(a.code);
						const [textB, numB] = sortKey(b.code);

						// First, compare the text parts
						if (textA < textB) return -1;
						if (textA > textB) return 1;

						// If the text parts are equal, compare the numeric parts
						return numA - numB;
					});
			}
			return [];
		},
		advancedDatasourceElasticData() {
			return this.$store.getters.getAdvancedDatasourceElasticData;
		},
		formAdvancedDatasource: {
			get() {
				return this.$store.getters.getAdvancedDatasourceStore[this.form.index];
			},
			set(value) {
				this.$store.commit('setFormAdvancedDatasourceObject', { index: this.form.index, data: value });
			}
		},
		displayMode: {
			get() {
				return this.$store.getters.getDisplayMode;
			},
			set(value) {
				this.$store.commit('setDisplayMode', value);
			}
		},
		dialogHeight() {
			if (this.displayLocation === 'dialog') {
				return document.getElementById('dialog-container').clientHeight;
			}
			return false;
		},
		historicFirstDates() {
			return this.$store.getters.getHistoricFirstDates;
		},
		historicLastDates() {
			return this.$store.getters.getHistoricLastDates;
		},
		pollutant() {
			return this.form.selectedPollutant ? this.pollutants.find((parameter) => parameter.elasticacronym == this.form.selectedPollutant) : null;
		}
	},

	watch: {
		'form.option': {
			handler(newVal) {
				if (newVal) {
					this.$nextTick(() => {
						if (this.$refs['advancedDatasourceChart']) {
							this.chartInstance = this.$refs['advancedDatasourceChart']; //.echartsInstance;
						} else {
							console.error('Chart instance is not available');
						}
					});
				}
			},
			immediate: true
		},
		displayMode: {
			handler(newValue, oldValue) {
				if (this.displayLocation === oldValue) {
					this.commitChanges();
				} else {
					this.restoreChanges();
					if (this.form.chartData && typeof this.form.chartData.message === 'object') {
						this.form.showChartOptions = true;
						if (!this.form.designOption) {
							this.form.designOption = this.form.configurations.find(
								(option) => option.value.id == this.parameterOption.value.id
							).value;
						}
						console.log('call changevisualization: displayMode', this.chart, this.form.designOption);

						if (!this.form.option) this.changeVisualization(this.form.designOption);
					}
				}
			}
		},
		feature() {
			//console.log('feature watch advanced', prev, val);
			this.form.startDateDisabled = true;
			this.form.endDateDisabled = true;
			this.form.startDate = this.form.endDate = null;
			this.form.infoStartDate = this.form.infoEndDate = this.$t('aqi.infoDateIntervalDisabled');
			this.deleteChart();
			this.form.chartData = null;
			this.form.showChartOptions = false;
			this.loadingAnimation = false;
			this.commitChanges();
		}
	},
	mounted() {
		this.loadDesignOptions();
		if (this.devices.length == 1) {
			this.devicesOptions = this.devices;
			this.form.selectedDevices = [this.devices[0].code];
		} else {
			this.devicesOptions = [this.selectAll].concat(this.devices);
		}
		if (this.pollutants.length == 1) this.form.selectedPollutant = [this.pollutants[0].elasticacronym];

		this.chartStyle = this.displayLocation == 'card' ? { height: '296px', width: '100%' } : { height: 'calc(70vh - 187px)', width: '85vw' };
		if (this.displayLocation == 'dialog') this.restoreChanges();

		if (this.form.chartData && !this.chart) {
			this.form.showChartOptions = true;
			this.changeVisualization(this.form.designOption);
		}
	},
	beforeDestroy() {
		//this.deleteChart();
	},
	methods: {
		deleteChart() {
			this.form.option = null;
			this.advancedProps = null;
		},
		visualizationConfiguration(visualization) {
			this.chartType = JSON.parse(visualization.configuration)['series'][0]['type'];
			let configuration = {
				text: visualization.visualizationname,
				value: {
					config: JSON.parse(visualization.configuration),
					id: visualization.acronym,
					applyranges: visualization.applyranges,
					exportable: visualization.exportable,
					showintervals: visualization.showintervals,
					name: visualization.visualizationname,
					description: visualization.description
				}
			};
			return configuration;
		},
		showDesignOptions() {
			this.form.showChartOptions = true;
			if (!this.form.configurations || !this.form.designOption) {
				const historicalChartOptions = ChartOptions(constants.VISUALIZATION_TYPE_HISTORIC);
				const promise = search(historicalChartOptions);
				promise.then((historicalChartData) => {
					this.historicalChartData = historicalChartData.data.data;
					this.historicalChartData.forEach((visualization) => {
						let existConfiguration = false;
						if (this.form.configurations.length > 0) {
							this.form.configurations.forEach((configuration) => {
								if (configuration.text == visualization['visualizationname']) existConfiguration = true;
							});
						}
						if (!existConfiguration) {
							this.form.configurations.push({
								text: visualization.visualizationname,
								value: {
									config: JSON.parse(visualization.configuration),
									id: visualization.acronym,
									applyranges: visualization.applyranges,
									exportable: visualization.exportable,
									showintervals: visualization.showintervals,
									name: visualization.visualizationname,
									description: visualization.description
								}
							});
						}
					});
					this.form.designOption = this.form.configurations.find((option) => option.value.id == this.parameterOption.value.id).value;
					return this.form.configurations;
				});
			}
			return this.form.configurations;
		},

		changeVisualization(design, elasticData = null) {
			this.loadingAnimation = true;
			this.form.showIntervals = false;
			if (!design) {
				design = this.parameterOption.value;
			}
			if (typeof design.value == 'object') {
				this.chartType = design.value.config['series'].length > 0 ? design.value.config['series'][0]['type'] : 'line';
				this.parameterConfiguration = design.value.config;
			} else {
				this.chartType = design.config['series'].length > 0 ? design.config['series'][0]['type'] : 'line';
				this.parameterConfiguration = design.config;
			}
			let designOption = this.form.designOption;
			if (this.historicalChartData.length > 0) {
				let visualization = this.historicalChartData.find((vis) => vis.acronym == design.id);
				if (visualization)
					designOption = {
						config: JSON.parse(visualization.configuration),
						id: visualization.acronym,
						applyranges: visualization.applyranges,
						exportable: visualization.exportable,
						showintervals: visualization.showintervals,
						name: visualization.visualizationname,
						description: visualization.description
					};
			}

			this.form.option = this.initChartDatasource(
				this.pollutant.name,
				this.pollutant.symbol,
				elasticData ?? this.form.chartData,
				this.form.selectedPollutant,
				designOption
			);
			this.loadingAnimation = false;
		},
		submitDateInterval() {
			let d = new Date();
			if (this.form.dateInterval) {
				if (this.form.dateInterval == -1) {
					this.showAdvancedDate = true;
					this.form.startDateDisabled = false;
					this.form.infoStartDate = this.$t('aqi.infoStartDate');
					this.form.infoEndDate = this.$t('aqi.infoEndDate');
					if (this.displayLocation == 'dialog') this.chartStyle.height = 'calc(70vh - 248px)';
					else this.chartStyle.height = '276px';
					this.commitChanges();
				} else {
					this.showAdvancedDate = false;
					this.loadingAnimation = true;
					this.form.startDateDisabled = true;
					this.form.startDate = this.form.endDate = null;
					this.form.infoStartDate = this.form.infoEndDate = this.$t('aqi.infoDateIntervalDisabled');
					if (this.displayLocation == 'dialog') this.chartStyle.height = 'calc(70vh - 187px)';

					d.setDate(d.getDate() - this.form.dateInterval);
					this.form.chartData = null;
					let indexName = obtainIndexName(this.feature);
					scrollData(
						indexName,
						query_AdvancedDataByParameter(this.form.selectedDevices, this.form.selectedPollutant, d.valueOf(), Date.now())
					).then((elasticData) => {
						if (elasticData.message.length > 0) {
							this.$store.commit('setAdvancedDatasourceElasticData', {
								index: this.form.index,
								data: elasticData
							});
							this.form.chartData = elasticData;
							this.showDesignOptions();
							this.changeVisualization(this.form.designOption, elasticData);
						} else {
							this.$store.commit('setAdvancedDatasourceElasticData', {
								index: this.form.index,
								data: null
							});
							this.loadingAnimation = false;
							this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
							this.form.showChartOptions = false;
							this.deleteChart();
						}
						this.commitChanges();
					});
				}
			} else {
				this.showAdvancedDate = false;
				this.form.startDateDisabled = true;
				this.form.endDateDisabled = true;
				this.form.startDate = this.form.endDate = null;
				this.form.infoStartDate = this.form.infoEndDate = this.$t('aqi.infoDateIntervalDisabled');
				this.deleteChart();
				this.form.chartData = null;
				this.form.showChartOptions = false;
				this.loadingAnimation = false;
				this.commitChanges();
			}
		},
		inputStartDate() {
			if (this.form.startDate) {
				this.form.endDateDisabled = false;
				if (this.form.endDate) this.submitEndDate();
				else this.commitChanges();
			} else {
				this.form.endDateDisabled = true;
				this.commitChanges();
			}
		},
		submitEndDate() {
			if (this.form.startDate && this.form.endDate) {
				this.loadingAnimation = true;
				this.form.chartData = null;
				let startDate = new Date(this.form.startDate);
				let endDate = new Date(this.form.endDate);
				let startPlusOne = startDate.getUTCDate() + 1;
				let endPlusOne = endDate.getUTCDate() + 1;
				startDate.setUTCHours(0, 0, 0, 0);
				startDate.setUTCDate(startPlusOne);
				endDate.setUTCHours(23, 59, 59, 0);
				endDate.setUTCDate(endPlusOne);
				console.log('startDate debug', startDate, endDate, startDate.valueOf(), endDate.valueOf());
				scrollData(
					obtainIndexName(this.feature),
					query_AdvancedDataByParameter(this.form.selectedDevices, this.form.selectedPollutant, startDate.valueOf(), endDate.valueOf())
				).then((elasticData) => {
					if (elasticData.message.length > 0) {
						this.$store.commit('setAdvancedDatasourceElasticData', elasticData);
						this.form.chartData = elasticData;
						this.showDesignOptions();
						this.changeVisualization(this.form.designOption, elasticData);
					} else {
						this.$store.commit('setAdvancedDatasourceElasticData', null);
						this.loadingAnimation = false;
						this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
						this.form.showChartOptions = false;
						this.deleteChart();
					}
					this.commitChanges();
				});
			}
		},
		commitChanges() {
			if (this.displayMode == this.displayLocation) {
				this.$store.commit('setFormAdvancedDatasourceObject', { index: this.form.index, data: this.form });
			}
		},
		restoreChanges() {
			if (this.displayMode == this.displayLocation) {
				this.$store.getters.getAdvancedDatasourceStore[this.form.index]
					? (this.form = this.$store.getters.getAdvancedDatasourceStore[this.form.index])
					: console.warn(
							'Note: Problem with historic store',
							this.$store.getters.getAdvancedDatasourceStore,
							this.form.index,
							this.$store.getters.getAdvancedDatasourceStore[this.form.index]
						);
				if (this.form.dateInterval) this.submitDateInterval();
				if (!this.form.chartData || !this.form.option) this.deleteChart();
			}
		},
		async loadDesignOptions() {
			const historicalChartOptions = ChartOptions(constants.VISUALIZATION_TYPE_HISTORIC);
			const { data } = await search(historicalChartOptions);
			this.form.configurations = [];
			this.historicalChartData = data.data;
			let vis = this.historicalChartData.find((vis) => vis.acronym == constants.VISUALIZATIONS.TIME_SERIES);

			this.parameterOption = this.visualizationConfiguration(vis);
			this.parameterConfiguration = this.parameterOption.value.config;
			this.form.designOption = this.parameterOption.value;
			this.form.configurations = [vis];

			return this.form.configurations;
		},
		dynamicInput(e) {
			//console.log('dynamic input', e, e.length, this.devices.length);
			if (Array.isArray(e)) {
				if (e[e.length - 1] == -1) {
					this.form.selectedDevices = [];
					this.selectAll.name = this.$t('panels.device.unselect-all');
					this.selectAll.code = -2;
					this.devices.forEach((device) => this.form.selectedDevices.push(device.code));
				} else if (e[e.length - 1] == -2) {
					this.selectAll.name = this.$t('panels.device.select-all');
					this.selectAll.code = -1;
					this.form.selectedDevices = [];
				} else if (e.length == this.devices.length) {
					this.selectAll.name = this.$t('panels.device.unselect-all');
					this.selectAll.code = -2;
				} else if (e.includes(-1)) {
					const index = this.form.selectedDevices.indexOf(-1);
					if (index > -1) {
						this.form.selectedDevices.splice(index, 1);
					}
				}
			}
			if (this.form.selectedPollutant && this.form.selectedDevices.length > 0 && this.form.dateInterval) {
				if (this.form.dateInterval === -1) {
					if (this.form.startDate && this.form.endDate) this.submitEndDate();
				} else this.submitDateInterval();
			} else {
				this.deleteChart();
			}
		},
		changedZoom() {
			let option = this.chartInstance.getOption();
			let minValue = 0;
			let maxValue = 0;
			let minValueDevice = this.form.selectedDevices[0];
			let maxValueDevice = this.form.selectedDevices[0];
			let minValueDate = '';
			let maxValueDate = '';
			let globalSum = 0;
			let globalCount = 0;
			let pollutantSymbol = this.pollutant.symbol;

			if (this.form.chartData) {
				let documents = this.form.chartData.message.map((hit) => hit._source);
				let filteredDocs = documents.filter(
					(doc) =>
						doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP] > option.dataZoom[0].startValue &&
						doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP] < option.dataZoom[0].endValue &&
						doc[this.form.selectedPollutant] >= option.dataZoom[1].startValue &&
						doc[this.form.selectedPollutant] <= option.dataZoom[1].endValue
				);
				if (filteredDocs.length > 0) {
					globalCount = filteredDocs.length;
					minValue = filteredDocs[0][this.form.selectedPollutant];
					filteredDocs.forEach((doc) => {
						if (doc[this.form.selectedPollutant] < minValue) {
							minValue = doc[this.form.selectedPollutant];
							minValueDevice = doc.device;
							minValueDate = doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP];
						}
						if (doc[this.form.selectedPollutant] > maxValue) {
							maxValue = doc[this.form.selectedPollutant];
							maxValueDevice = doc.device;
							maxValueDate = doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP];
						}
						globalSum += doc[this.form.selectedPollutant];
					});
					this.metricAverage =
						(globalSum > 0
							? parseInt(globalSum / globalCount) === globalSum / globalCount
								? globalSum / globalCount
								: (globalSum / globalCount).toFixed(2)
							: '-') + (pollutantSymbol || '');

					this.metricMin =
						minValue.toFixed(2) +
						(pollutantSymbol || '') +
						' (' +
						minValueDevice +
						') - ' +
						getDateByFormat(minValueDate, this.userProperties.constants);

					this.metricMax =
						maxValue.toFixed(2) +
						(pollutantSymbol || '') +
						' (' +
						maxValueDevice +
						') - ' +
						getDateByFormat(maxValueDate, this.userProperties.constants);

					console.log('datazoom metrics', this.metricAverage, this.metricMax, this.metricMin);
				} else {
					this.metricAverage = this.metricMax = this.metricMin = '-';
				}
			}
		},
		toggleDisplayMode() {
			// Toggle between 'dialog' and 'card' display modes
			this.displayMode = this.displayMode === 'dialog' ? 'card' : 'dialog';
			switch (this.displayMode) {
				case 'card':
					document.documentElement.style.setProperty('--map-control-bottom-position', CARD_HEIGHT + 'px');
					break;
				case 'dialog':
					document.documentElement.style.setProperty('--map-control-bottom-position', '0px');
					break;
			}
		}
	}
};
</script>

<style lang="postcss">
.historic-chart,
.historic-notification-chart,
.advanced-chart {
	& .chart-historic {
		height: calc(70vh - 187px);
		width: 85vw;
	}
	& #intervals-btn {
		background-color: var(--gris-50);
		border-radius: 8px;
	}
	& #intervals-btn.active {
		color: var(--azul-primary-100) !important;
		background-color: var(--azul-primary-100) !important;
	}
	.v-input__prepend-inner {
		padding-top: 3px;
		padding-left: 4px;
		& .v-icon {
			font-size: 18px;
			font-weight: 500;
		}
	}
}
/* Global CSS for historic and dashboard */
.dashboard-content,
.historic-chart,
.advanced-chart {
	& .vue-echarts-inner > div:last-child > div:last-child > div[style*='cursor: pointer'] {
		background-color: var(--azul-primary-100) !important;
		color: white;
		font-size: 14px !important;
		padding: 6px 12px !important;
	}
}
.data-view-table td {
	border: 1px solid black;
}
</style>
