<template>
	<v-tooltip top :color="tooltipColor" transition="none">
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				icon
				@click="$emit('toggleShowIntervals')"
				class="pa-1 mr-2 ml-1 mt-4 pt-1 intervals-btn"
				:class="{ active: showIntervals }"
				:height="height"
				:min-width="width"
				v-bind="attrs"
				v-on="on"
				:disabled="saving"
				id="intervals-btn"
			>
				<v-icon class="fw-bold fa fa-chart-area" :color="showIntervals ? '#fff' : 'var(--gris-primary-active)'" size="18"></v-icon>
				<!-- <i v-if="form.showIntervals" class="fas fa-solid fa-chart-area"></i>
								<i v-else class="fas fa-regular fa-chart-area"></i> -->
			</v-btn>
		</template>
		<span class="fs-regular">{{ showIntervals ? $t('map.hideIntervals') : $t('map.showIntervals') }}</span>
	</v-tooltip>
</template>

<script>
export default {
	props: {
		elasticData: {
			type: Object,
			required: true
		},
		designOption: {
			type: Object,
			required: true
		},
		pollutant: {
			type: Object,
			required: true
		},
		showIntervals: {
			type: Boolean,
			required: true
		},
		method: {
			type: String,
			default: 'initChart',
			required: false
		},
		tooltipColor: {
			type: String,
			default: 'var(--azul-primary-100)'
		},
		spacingClass: {
			type: String,
			default: 'mb-1'
		},
		height: {
			default: 38
		},
		width: {
			default: 42
		}
	},
	data() {
		return {
			saving: false
		};
	},
	methods: {
		showIntervalsInput() {
			console.log('debug parent', this.$parent);
			console.log('show intervals input', !!this.$parent.form.option, this.$parent.form.showIntervals);
			this.$parent.form.showIntervals = !this.$parent.form.showIntervals;
			if (this.$parent.form.showIntervals) {
				this.$parent.methods.deleteChart();
				this.$parent.form.option = this.$parent.methods[this.method](
					this.pollutant.name,
					this.pollutant.symbol,
					this.elasticData,
					this.pollutant.elasticacronym,
					this.designOption
				);
				console.log('intervals check', this.$parent.form.showIntervals, this.$parent.form.option, this.optionRange);
			} else {
				this.$parent.methods.deleteChart();
				this.$parent.loadingAnimation = true;
				setTimeout(() => {
					this.$parent.form.option = this.$parent.methods[this.method](
						this.pollutant.name,
						this.pollutant.symbol,
						this.elasticData,
						this.pollutant.elasticacronym,
						this.designOption
					);
				}, 100);
				this.$parent.loadingAnimation = false;

				console.log('intervals check', this.$parent.form.showIntervals, this.$parent.form.option);
			}
		}
	}
};
</script>

<style></style>
