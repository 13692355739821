<template>
	<div class="pt-0 w-100">
		<v-form v-model="compareAqiForm" ref="form" style="max-height: 91px; overflow: hidden auto">
			<div row wrap>
				<v-layout wrap class="px-3">
					<v-flex xs12 :md6="!showAdvancedDate" :md4="showAdvancedDate">
						<v-select
							:label="$t('aqi.dateInterval')"
							toplabel
							clearable
							required
							:disabled="form.formDisabled"
							v-model="form.dateInterval"
							:items="intervals"
							@input="submitDateInterval"
							class="form-control-select mt-2 px-1"
						></v-select>
					</v-flex>
					<v-flex v-if="showAdvancedDate" xs12 md4>
						<pui-date-field
							:label="$t('aqi.startDate')"
							toplabel
							:required="!form.startDateDisabled"
							v-model="form.startDate"
							:disabled="form.startDateDisabled"
							:min="firstDateAqi"
							:max="lastDateAqi ?? Date.now()"
							:tooltipDescription="form.infoStartDate"
							@input="inputStartDate"
						></pui-date-field>
					</v-flex>
					<v-flex v-if="showAdvancedDate" xs12 md4>
						<pui-date-field
							:label="$t('aqi.endDate')"
							toplabel
							:required="!form.endDateDisabled"
							v-model="form.endDate"
							:disabled="form.endDateDisabled"
							:min="form.startDate"
							:max="lastDateAqi ?? Date.now()"
							:tooltipDescription="form.infoEndDate"
							@input="submitEndDate"
						></pui-date-field>
					</v-flex>
				</v-layout>
			</div>
		</v-form>
		<!-- Loading animation -->
		<div class="d-flex justify-center align-center" v-if="loadingAnimation" :style="chartStyle">
			<v-progress-circular indeterminate :color="primaryColor"></v-progress-circular>
		</div>
		<!--  echarts component -->
		<div ref="aqiechart" class="d-flex justify-center align-center" v-show="!loadingAnimation" :style="chartStyle" />
	</div>
</template>

<script>
import * as echarts from 'echarts';

// eslint-disable-next-line no-unused-vars
import { search, getDateByFormat, obtainIndexName, isSuperAdmin } from '@/api/common';

import { lastData /* getIndexFromElastic */ } from '@/api/databases_API';
import { query_CompareAQIDataByParameter } from '@/utils/queries';
import QueriesSQLMixin from '@/mixins/QueriesSQLMixin';

//import constants from '@/utils/constants';

export default {
	name: 'CompareAQIChart',
	mixins: [QueriesSQLMixin],
	data() {
		return {
			width: '100%',
			height: '100%',
			chart: null,
			pollutantNoSelected: true,
			xData: [],
			series: [],

			form: {
				dateInterval: null,
				startDate: null,
				endDate: null,
				formDisabled: false,
				startDateDisabled: true,
				endDateDisabled: true,
				infoStartDate: this.$t('aqi.infoDateIntervalDisabled'),
				infoEndDate: this.$t('aqi.infoDateIntervalDisabled'),
				designOption: null,
				configurations: []
			},
			chartData: null,
			intervals: [
				/* { text: this.$t('aqi.chooseInterval'), value: '0' }, */
				{ text: this.$t('aqi.last24h'), value: 1 },
				{ text: this.$t('aqi.last48h'), value: 2 },
				{ text: this.$t('aqi.lastWeek'), value: 7 },
				{ text: this.$t('aqi.last15D'), value: 15 },
				{ text: this.$t('aqi.lastMonth'), value: 30 },
				{ text: this.$t('aqi.customInterval'), value: -1 }
			],

			compareAqiForm: null,
			loadingAnimation: false,
			chartType: '',
			compareAqiChartData: {},
			chartStyle: {},
			parameterConfiguration: {},
			showAdvancedDate: false,
			aqiByDate: null,
			firstDateAqi: null,
			lastDateAqi: null,
			viewName: '"v_pm_aqi_values" AS pav',
			primaryColor: this.$store.state.global.primaryColor
		};
	},
	components: {},
	props: {
		deviceObject: {
			type: Object,
			required: true
		},
		formData: {
			type: Object,
			required: true
		},
		displayLocation: {
			type: String,
			required: true
		}
	},
	computed: {
		selectedTabDevice: {
			get() {
				return this.$store.getters.getSelectedTabDevice;
			},
			set(value) {
				this.$store.commit('setSelectedTabDevice', value);
			}
		},
		pollutants() {
			return this.$store.getters.getParameters.filter((pollutant) => pollutant.pmdatasourcetypeid == this.deviceObject.pmdatasourcetypeid);
		},
		pollutant() {
			return this.$store.getters.getParameters.find((pollutant) => pollutant.acronym == 'AQI');
		},
		compareAqiElasticData: {
			get() {
				return this.$store.getters.getCompareAqiElasticData;
			},
			set(value) {
				this.$store.commit('setCompareAqiElasticData', value);
			}
		},
		formCompareAqi: {
			get() {
				return this.$store.getters.getCompareAqiStore;
			},
			set(value) {
				this.$store.commit('setFormCompareAqiObject', value);
			}
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		},
		dialogHeight() {
			if (this.displayLocation === 'dialog') {
				return document.getElementById('dialog-container').clientHeight;
			}
			return false;
		},
		compareAqiFirstDates() {
			return this.$store.getters.getCompareAqiFirstDates;
		},
		compareAqiLastDates() {
			return this.$store.getters.getCompareAqiLastDates;
		}
	},

	watch: {
		selectedTabDevice: {
			handler() {
				setTimeout(this.resizeChart, 50);
			}
		},
		displayMode: {
			handler(newValue, oldValue) {
				if (this.displayLocation === oldValue) {
					this.commitChanges(this.chartData);
				} else {
					this.restoreChanges();
					if (this.chartData && typeof this.chartData.message === 'object') {
						if (!this.form.designOption) {
							this.form.designOption = this.form.configurations[0];
						}
						let elasticData = this.compareAqiElasticData.elastic;
						let databaseData = this.compareAqiElasticData.database;
						this.initChart(this.pollutant.name, this.pollutant.symbol, elasticData, databaseData, this.pollutant.elasticacronym);
					}
				}
				if (this.form.dateInterval == -1) {
					this.showAdvancedDate = true;
				}
			}
		},
		'form.dateInterval': {
			handler(newValue) {
				if (!newValue) {
					this.loadingAnimation = false;
					this.chartData = null;
					this.showAdvancedDate = false;
				}
			}
		}
	},
	created() {},
	mounted() {
		this.chartStyle = this.displayLocation == 'card' ? { height: '250px', width: '100%' } : { height: 'calc(70vh - 187px)', width: '85vw' };
		this.getCompareAqiDate(this.viewName, this.deviceObject.pmareaid, this.deviceObject.pmorganizationid, 1, 'DESC').then((result) => {
			this.lastDateAqi = result[0]['date_timestamp'] * 1000;
		});
		this.getCompareAqiDate(this.viewName, this.deviceObject.pmareaid, this.deviceObject.pmorganizationid, 1, 'ASC').then((result) => {
			this.firstDateAqi = result[0]['date_timestamp'] * 1000;
		});

		if (this.displayLocation == 'dialog') {
			this.restoreChanges();
		}

		if (this.displayLocation == 'dialog') {
			var echartContainer = this.$refs['aqiechart'];
			//Used to make the chart adaptive to height and width, and calculate the height and width of the container through the height and width of the form
			if (echartContainer) {
				echartContainer.style.height = 'calc(70vh - 187px)';
				echartContainer.style.width = '85vw';
			}
		}
		if (
			this.compareAqiElasticData &&
			Object.hasOwn(this.compareAqiElasticData, 'elastic') &&
			Object.hasOwn(this.compareAqiElasticData, 'database')
		) {
			let databaseData = this.compareAqiElasticData.database;
			let elasticData = this.compareAqiElasticData.elastic;
			this.initChart(this.pollutant.name, this.pollutant.symbol, elasticData, databaseData, this.pollutant.elasticacronym);
		}
	},
	updated() {},
	beforeDestroy() {
		this.deleteChart();
	},
	methods: {
		deleteChart() {
			if (!this.chart) {
				return;
			}
			this.chart.dispose();
			this.chart = null;
			window.removeEventListener('resize', this.resizeChart);
		},
		resizeChart() {
			if (this.chart) {
				this.chart.resize();
			}
		},
		submitDateInterval() {
			let d = new Date();
			if (this.form.dateInterval) {
				if (this.form.dateInterval == -1) {
					this.showAdvancedDate = true;
					this.form.startDateDisabled = false;
					this.form.infoStartDate = this.$t('aqi.infoStartDate');
					this.form.infoEndDate = this.$t('aqi.infoEndDate');
					this.commitChanges();
				} else {
					this.showAdvancedDate = false;
					this.loadingAnimation = true;
					this.form.startDateDisabled = true;
					this.form.startDate = this.form.endDate = null;
					this.form.infoStartDate = this.form.infoEndDate = this.$t('aqi.infoDateIntervalDisabled');

					d.setUTCDate(d.getUTCDate() - this.form.dateInterval);
					d.setUTCHours(0, 0, 0, 0);
					this.deleteChart();
					this.chartData = {};
					var indexName = obtainIndexName(this.deviceObject);
					let aqiPromises = [];
					aqiPromises.push(this.getMaxAqiValue(d.valueOf()));
					Promise.all(aqiPromises).then((resultPromises) => {
						let databaseData = resultPromises[0];
						if (databaseData.length > 0) {
							let promisesElastic = [];
							databaseData.forEach((aqiObj) => {
								let dateOriginal = new Date(aqiObj.datetimestamp * 1000);
								let datePlusOne = new Date(aqiObj.datetimestamp * 1000);
								let dayPlusOne = dateOriginal.getUTCDate() + 1;
								datePlusOne.setUTCDate(dayPlusOne);
								promisesElastic.push(
									lastData(
										indexName,
										query_CompareAQIDataByParameter(
											this.deviceObject.code,
											this.pollutant.elasticacronym,
											dateOriginal.valueOf(),
											datePlusOne.valueOf()
										)
									)
								);
							});

							Promise.all(promisesElastic).then((resultPromises) => {
								let elasticDataArray = [];
								resultPromises.forEach((elasticData) => {
									if (elasticData.message.length > 0) {
										elasticDataArray.push(elasticData.message[0]['_source']);
										//this.initChart(this.pollutant.acronym, this.pollutant.symbol, elasticData, this.pollutant.elasticacronym);
									} else {
										elasticDataArray.push({ _source: { airQualityIndex: null, dateObserved_timestamp: null } });
									}
								});
								this.$store.commit('setCompareAqiElasticData', elasticDataArray);
								this.loadingAnimation = false;
								if (elasticDataArray.length == databaseData.length) {
									this.initChart(
										this.pollutant.name,
										this.pollutant.symbol,
										elasticDataArray,
										databaseData,
										this.pollutant.elasticacronym
									);
									this.chartData = { elastic: elasticDataArray, database: databaseData };
								} else {
									this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
								}
								this.commitChanges(this.chartData);
							});
						} else {
							this.$puiNotify.info(this.$t('common.apology'), this.$t('aqi.noDataCAMS'));
							this.loadingAnimation = false;
							this.deleteChart();
							this.chartData = {};
							this.commitChanges(this.chartData);
						}
					});
				}
			} else {
				this.form.startDateDisabled = true;
				this.form.endDateDisabled = true;
				this.form.startDate = this.form.endDate = null;
				this.form.infoStartDate = this.form.infoEndDate = this.$t('aqi.infoDateIntervalDisabled');
				this.deleteChart();
				this.chartData = {};
				this.loadingAnimation = false;
				this.commitChanges(null);
			}
		},
		inputStartDate() {
			if (this.form.startDate) {
				this.form.endDateDisabled = false;

				if (this.form.endDate) {
					this.submitEndDate(); // better usability
				} else {
					this.commitChanges();
				}
			} else {
				this.form.endDateDisabled = true;
				this.commitChanges();
			}
		},
		submitEndDate() {
			if (this.form.startDate && this.form.endDate) {
				this.deleteChart();
				this.loadingAnimation = true;
				this.chartData = {};

				let startDate = new Date(this.form.startDate);
				let endDate = new Date(this.form.endDate);
				let startPlusOne = startDate.getUTCDate() + 1;
				let endPlusOne = endDate.getUTCDate() + 1;
				startDate.setUTCHours(0, 0, 0, 0);
				startDate.setUTCDate(startPlusOne);
				endDate.setUTCHours(23, 59, 59, 0);
				endDate.setUTCDate(endPlusOne);

				var indexName = obtainIndexName(this.deviceObject);
				let aqiPromises = [];

				aqiPromises.push(this.getMaxAqiValue(startDate.valueOf(), endDate.valueOf()));
				Promise.all(aqiPromises).then((resultPromises) => {
					let databaseData = resultPromises[0];
					if (databaseData.length > 0) {
						let promisesElastic = [];
						databaseData.forEach((aqiObj) => {
							let dateOriginal = new Date(aqiObj.datetimestamp * 1000);
							let datePlusOne = new Date(aqiObj.datetimestamp * 1000);
							let dayPlusOne = dateOriginal.getUTCDate() + 1;
							datePlusOne.setUTCDate(dayPlusOne);
							promisesElastic.push(
								lastData(
									indexName,
									query_CompareAQIDataByParameter(
										this.deviceObject.code,
										this.pollutant.elasticacronym,
										dateOriginal.valueOf(),
										datePlusOne.valueOf()
									)
								)
							);
						});

						Promise.all(promisesElastic).then((resultPromises) => {
							let elasticDataArray = [];
							resultPromises.forEach((elasticData) => {
								if (elasticData.message.length > 0) {
									elasticDataArray.push(elasticData.message[0]['_source']);
									//this.initChart(this.pollutant.acronym, this.pollutant.symbol, elasticData, this.pollutant.elasticacronym);
								} else {
									elasticDataArray.push({ _source: { airQualityIndex: null, dateObserved_timestamp: null } });
								}
							});
							this.$store.commit('setCompareAqiElasticData', elasticDataArray);
							this.loadingAnimation = false;
							if (elasticDataArray.length == databaseData.length) {
								this.initChart(
									this.pollutant.name,
									this.pollutant.symbol,
									elasticDataArray,
									databaseData,
									this.pollutant.elasticacronym
								);
								this.chartData = { elastic: elasticDataArray, database: databaseData };
							} else {
								this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
							}
							this.commitChanges(this.chartData);
						});
					} else {
						this.$puiNotify.info(this.$t('common.apology'), this.$t('aqi.noDataCAMS'));
						this.loadingAnimation = false;
						this.deleteChart();
						this.chartData = {};
						this.commitChanges(this.chartData);
					}
				});
			}
			this.commitChanges();
		},
		commitChanges(data) {
			this.$store.commit('setFormCompareAqiObject', this.form);
			this.compareAqiElasticData = data;
		},
		restoreChanges() {
			if (this.displayMode == this.displayLocation) {
				this.form = this.$store.getters.getCompareAqiStore;
				this.chartData = this.compareAqiElasticData;
				if (Object.hasOwn(this.chartData, 'elastic')) {
					this.deleteChart();
					this.initChart(
						this.pollutant.name,
						this.pollutant.symbol,
						this.chartData.elastic,
						this.chartData.database,
						this.pollutant.elasticacronym
					);
				}
			}
		},
		getFirstDateParameter() {
			return this.compareAqiFirstDates || null;
		},
		getLastDateParameter() {
			return this.compareAqiLastDates || null;
		},
		async getMaxAqiValue(startDate, endDate = null) {
			let rulesAqi = [
				{ field: 'disabled', op: 'eq', data: 0 },
				{ field: 'pmareaid', op: 'eq', data: this.deviceObject.pmareaid },
				{ field: 'datetimestamp', op: 'ge', data: Math.floor(startDate / 1000) }
				/* { field: 'date', op: 'eq', data: Math.floor(Date.now() / 1000) } */
			];
			if (endDate) {
				rulesAqi.push({ field: 'datetimestamp', op: 'le', data: Math.floor(endDate / 1000) });
			}
			if (!isSuperAdmin(this.session.profiles[0])) {
				rulesAqi.push({ field: 'pm_organization_id', op: 'eq', data: this.userProperties.organizationid });
			}

			const body = {
				model: 'vluppmaqivalues',
				filter: {
					groups: [],
					groupOp: 'and',
					rules: rulesAqi
				},
				rows: 10000
			};

			const { data } = await search(body);
			let aqiFields = {
				ozonevalue: 'o3conc',
				no2value: 'no2conc',
				so2value: 'so2conc',
				pm25value: 'pm2p5conc',
				pm10value: 'pm10conc'
			};
			let aqiByDate = this.groupByDate(data.data);
			aqiByDate.forEach((aqiGroup) => {
				let maxValue = -1;
				let maxConc = 0;
				let index = 0;
				aqiGroup.aqiValues.forEach((aqiValue, i) => {
					if (maxValue == -1 || aqiValue.maxvalue > maxValue) {
						maxValue = aqiValue.maxvalue;
						let maxConcTemp = aqiValue[aqiFields[aqiValue.maxvaluename]];
						//let maxConcTemp = aqiValue[aqiFields['pm25value']];
						if (maxConc == 0 || maxConcTemp > maxConc) {
							maxConc = maxConcTemp;
							index = i;
						}
					}
				});
				aqiGroup.aqiValues[index]['maxConc'] = parseFloat(maxConc.toFixed(2));
				aqiGroup.aqiValues = aqiGroup.aqiValues[index];
			});
			this.aqiByDate = aqiByDate;
			return aqiByDate;
		},
		initChart(pollutantAcronym, pollutantSymbol, elasticDataArray, databaseData, elasticacronym) {
			this.loadingAnimation = false;
			this.deleteChart();
			let indexes = ['database', 'elastic'];
			var pollutantValues = { database: [], elastic: [] };
			var pollutantDays = { database: [], elastic: [] };
			elasticDataArray.forEach((elasticData) => {
				pollutantValues.elastic.push(parseFloat(parseFloat(elasticData[elasticacronym]).toFixed(2)));
			});
			databaseData.forEach((dateGroup) => {
				pollutantDays.database.push(dateGroup.datevalue);
				pollutantValues.database.push(dateGroup.aqiValues.maxConc);
			});

			this.chart = echarts.init(this.$refs['aqiechart']);
			window.addEventListener('resize', this.resizeChart);

			var option = {};

			option = this.parameterConfiguration;
			let pollutantTitle =
				pollutantSymbol && pollutantSymbol.trim().length > 0 ? pollutantAcronym + ' (' + pollutantSymbol + ')' : pollutantAcronym;

			let axis = 0;
			let data = {};
			let series = [];
			let xAxis = [];
			let yAxis = [];
			let toolbox = {};
			let dataZoom = [];
			let tooltip = {};

			indexes.forEach((index) => {
				data = { values: pollutantValues[index], days: pollutantDays['database'] };
				let origin = index == 'database' ? 'CAMS' : 'In situ';
				let markPointData = [{ type: 'average', name: 'Avg' }];
				if (pollutantValues[index].length > 1) {
					markPointData.push({ type: 'min', name: 'Min' });
				}

				series.push({
					name: pollutantAcronym + ' (' + origin + ')',
					type: 'line',
					xAxisIndex: axis,
					smooth: true,
					emphasis: {
						focus: 'series'
					},
					markLine: {
						data: markPointData
					},
					markPoint: {
						data: [{ type: 'max', name: 'Max' }],
						symbol: 'pin',
						symbolSize: 50,
						label: {
							formatter: pollutantSymbol ? '{@score} ' + pollutantSymbol : '{@score}'
						}
					},
					label: {
						formatter: pollutantSymbol ? '{@score} ' + pollutantSymbol : '{@score}'
					},
					data: data.values
				});

				axis++;

				xAxis.push({
					type: 'category',
					axisTick: {
						alignWithLabel: true
					},
					axisLine: {
						onZero: false
					},
					data: data.days
				});
			});
			yAxis = [{ type: 'value' }];
			toolbox = {
				right: 10,
				feature: {
					restore: {},
					saveAsImage: {}
				}
			};
			dataZoom = [
				{
					startValue: data.days[0]
				},
				{
					type: 'inside'
				}
			];
			tooltip = {
				trigger: 'axis'
			};

			option['legend'] = { data: [pollutantTitle] };
			option['series'] = series;
			option['xAxis'] = xAxis;
			option['yAxis'] = yAxis;
			option['toolbox'] = toolbox;
			option['dataZoom'] = dataZoom;
			option['tooltip'] = tooltip;

			option && this.chart.setOption(option);
		},
		groupByDate(dataset) {
			let dateConstant = this.session.constants;
			const object = dataset.reduce((r, { date, datetimestamp, ...rest }) => {
				if (!r[date])
					r[date] = {
						date,
						datevalue: getDateByFormat(datetimestamp * 1000, dateConstant)
							.toString()
							.split(' ')[0],
						datetimestamp,
						aqiValues: [rest]
					};
				else r[date].aqiValues.push(rest);
				return r;
			}, {});
			return Object.values(object);
		}
	}
};
</script>

<style scoped>
.topOptions {
	display: flex;
	flex: auto;
	justify-content: space-between;
	align-content: center;
}
</style>
