import { getDateByFormat, getStatusProperties } from '@/api/common';
import constants from '@/utils/constants';

export default {
	data() {},
	methods: {
		initChart(pollutantAcronym, pollutantSymbol, historicPollutantData, elasticacronym, designOption = null) {
			//Proceso datos historicos del contaminante
			let pollutantValues = [];
			let pollutantDays = [];

			console.info('debug historic data', historicPollutantData, this.chartType, designOption.id);
			if (historicPollutantData && typeof historicPollutantData.message !== 'string') {
				historicPollutantData.message.forEach((item) => {
					pollutantDays.push(getDateByFormat(item._source[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties.constants));
					pollutantValues.push(parseFloat(item._source[elasticacronym].toFixed(2)));
				});
			}

			//Configuro opciones del grafico
			let option = designOption ? designOption.config : this.parameterConfiguration;
			console.info('info: chart option', option, designOption, this.parameterConfiguration);

			let pollutantTitle =
				pollutantSymbol && pollutantSymbol.trim().length > 0 ? pollutantAcronym + ' (' + pollutantSymbol + ')' : pollutantAcronym;
			if (option['series'].length > 0) option['series'][0].name = pollutantAcronym;

			console.log('apply ranges init', pollutantValues, this.form.designOption, option['xAxis'], option['series'][0]);
			if (Object.hasOwn(option, 'xAxis') && typeof option['xAxis']['data'] === 'string') {
				// FREQUENCY (HISTOGRAM)
				const xAxisFunction = eval(option['xAxis']['data']);
				option['xAxis']['data'] = xAxisFunction(pollutantValues);
				if (typeof option['series'][0].data === 'string') {
					const seriesFunction = eval(option['series'][0].data);
					option['series'][0].data = seriesFunction(pollutantValues);
				}
				option['series'][0].itemStyle = {
					color: this.noStatusColor
				};
			} else if (option['series'].length > 0 && typeof option['series'][0]['data'] === 'string') {
				// FREQUENCY (PIE CHART)
				const seriesFunction = eval(option['series'][0].data);
				option['series'][0].data = seriesFunction(pollutantValues);
				option.tooltip.formatter = eval(option.tooltip.formatter);
			} else {
				option['legend'] = { data: [pollutantTitle], top: '3%' };
				option['series'][0].name = pollutantTitle;
				if (Object.hasOwn(option, 'xAxis')) option['xAxis']['data'] = pollutantDays;

				let pieces = [];
				let parameterStatus = this.$store.getters.getParameterStatus.find((status) => status.pmparameterid === this.pollutant.pmparameterid);

				if (parameterStatus && designOption.applyranges === 1) {
					const statusArray = JSON.parse(parameterStatus.status);
					const colors = JSON.parse(parameterStatus.color);
					statusArray.sort((a, b) => {
						const minimumA = a.min.data;
						const minimumB = b.min.data;
						if (minimumA < minimumB) {
							return -1;
						}
						if (minimumA > minimumB) {
							return 1;
						}
						return 0; // Objects have the same minimum (statement should be unreachable)
					});
					statusArray.forEach((status, i) => {
						pieces.push({
							/* gte: status.minvalue,
							lte: status.maxvalue, */
							[status.min.op]: status.min.data,
							[status.max.op]: status.max.data,
							color: colors[i]
						});
					});

					option['visualMap'] = {
						top: '20%',
						right: '3%',
						pieces: pieces,
						outOfRange: {
							color: this.noStatusColor
						}
					};
				} else {
					option['series'][0].lineStyle = {
						color: this.noStatusColor
					};
					if (Object.hasOwn(option.series[0], 'areaStyle')) option.series[0].areaStyle = { color: this.noStatusColor };
				}

				option['series'][0].data = pollutantValues.map((value) => {
					const piece = pieces.find((p) => {
						let min = null;
						let max = null;
						if (p.gt) min = 'gt';
						if (p.gte) min = 'gte';
						if (p.lt) max = 'lt';
						if (p.lte) max = 'lte';
						const isEqual = p[min] === p[max];
						if (isEqual) {
							// Aplicar tolerancia de 0.1 si los límites son iguales
							return value >= p[min] && value - 0.1 <= p[max];
						} else {
							if (min && max) {
								return value >= p[min] && value <= p[max];
							} else if (min) {
								return value >= p[min];
							} else if (max) {
								return value <= p[max];
							}
						}
					});

					return {
						value: value,
						itemStyle: {
							color: piece ? piece.color : this.noStatusColor
						}
					};
				});
				option['series'][0].markPoint = {
					data: [
						{ type: 'max', name: 'Max' },
						{ type: 'min', name: 'Min' }
					],
					symbol: 'pin',
					symbolSize: 50,
					label: {
						formatter: pollutantSymbol ? '{@score}' + pollutantSymbol : '{@score}'
					}
				};
				option['series'][0].markLine = {
					data: [{ type: 'average', name: 'Avg' }]
				};
			}
			if (option.toolbox && Object.hasOwn(option.toolbox, 'feature')) {
				option.toolbox.top = '3%';
				option.toolbox.right = '1%';
				if (Object.hasOwn(option.toolbox.feature, 'dataView')) {
					option['toolbox']['feature']['dataView']['title'] = this.$t('charts.title');
					option['toolbox']['feature']['dataView']['lang'] = [
						this.$t('charts.dataView'),
						this.$t('charts.closeView'),
						this.$t('charts.updateData')
					];

					option.toolbox.feature.dataView.optionToContent = this.setOptionToContent(designOption.id);
				}
				if (Object.hasOwn(option.toolbox.feature, 'restore')) {
					option['toolbox']['feature']['restore']['title'] = this.$t('charts.restore');
				}
				if (Object.hasOwn(option.toolbox.feature, 'saveAsImage')) {
					option['toolbox']['feature']['saveAsImage']['title'] = this.$t('charts.saveAsImage');
				}
			}

			option['series'][0].type = this.chartType;
			//console.log('show intervals init', this.form.showIntervals, option);
			if (this.form.showIntervals) {
				option['series'][0].markArea = {
					silent: true,
					data: [[{ yAxis: this.pollutant.thresholdminvalue }, { yAxis: this.pollutant.thresholdmaxvalue }]],
					itemStyle: { borderType: 'dashed', borderWidth: 3, borderColor: 'red' }
				};
			} else {
				if (Object.hasOwn(option['series'][0], 'markArea')) delete option['series'][0].markArea;
			}
			option['textStyle'] = {
				fontFamily: 'Montserrat, sans-serif',
				fontSize: 14
			};
			option.grid = {
				height: '60%',
				bottom: '23%',
				left: '4%',
				width: '87%'
			};
			//console.log('set historic option: ', this.displayLocation, option, JSON.stringify(option), option.series[0].data.length);
			return option;
		},
		initHeatMap(pollutantAcronym, pollutantSymbol, historicPollutantData, elasticacronym, designOption = null) {
			console.info('debug historic data HMAP', historicPollutantData, this.chartType, designOption);
			let minValue = 0;
			let maxValue = 300;
			const dayCount = this.form.dateInterval + 1;
			const hourLabels = Array.from({ length: 24 }, (_, i) => i);
			const dayLabels = Array.from({ length: dayCount }, (_, i) =>
				getDateByFormat(Date.now() - i * 24 * 60 * 60 * 1000, this.userProperties.constants, true)
			);

			let valueCount = {};
			let heatmapData = {};
			dayLabels.forEach((day) => {
				valueCount[day] = Array(24).fill(0);
				heatmapData[day] = Array(24).fill(0);
			});

			console.log('debug heatmap data', heatmapData, hourLabels, dayLabels, dayCount);
			console.log('debug heatmap data', valueCount);
			if (historicPollutantData && typeof historicPollutantData.message !== 'string') {
				historicPollutantData.message.forEach((item) => {
					const date = new Date(item._source[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP]);
					//console.log('debug heatmap dates', date, date.getHours(), getDateByFormat(date.valueOf(), this.userProperties.constants, true));
					const hour = date.getHours();
					const dayIndex = dayLabels.indexOf(getDateByFormat(date.valueOf(), this.userProperties.constants, true));
					const value = item._source[elasticacronym];
					if (dayIndex !== -1) {
						heatmapData[dayLabels[dayIndex]][hour] += value;
						valueCount[dayLabels[dayIndex]][hour] += 1; // Contador
					}
				});
				if (Object.hasOwn(historicPollutantData, 'aggs')) {
					minValue = historicPollutantData.aggs['min_' + elasticacronym].value;
					maxValue = historicPollutantData.aggs['max_' + elasticacronym].value;
				}
			}

			// Calcular la media
			let formattedHeatmapData = [];
			dayLabels.forEach((day) => {
				heatmapData[day].forEach((hourValue, hourIndex) => {
					formattedHeatmapData.push([hourIndex, day, hourValue ? parseFloat((hourValue / valueCount[day][hourIndex]).toFixed(2)) : null]);
				});
			});

			/* heatmapData.forEach((data, index) => {
				const hour = Math.floor(index / dayCount);
				const dayIndex = index % dayCount;
				if (valueCount[hour][dayIndex] > 0) {
					data[2] /= valueCount[data][dayIndex]; // Obtengo la media
				} else {
					data[2] = 0; // Si no hay valores, pinto un 0
				}
			}); */
			let pollutantTitle =
				pollutantSymbol && pollutantSymbol.trim().length > 0 ? pollutantAcronym + ' (' + pollutantSymbol + ')' : pollutantAcronym;
			let option = designOption ? designOption.config : this.parameterConfiguration;

			pollutantSymbol = pollutantSymbol ? pollutantSymbol : '';
			option.tooltip.formatter = (params) => {
				return (
					'Hour: ' +
					(params.data[0] < 10 ? '0' : '') +
					params.data[0] +
					':00<br/>Day: ' +
					params.data[1] +
					'<br/>Average value: ' +
					params.data[2] +
					' ' +
					pollutantSymbol
				);
			};
			option.xAxis.data = hourLabels.map((hour) => {
				if (hour < 10) return '0' + hour + ':00';
				else return hour + ':00';
			});
			option.yAxis.data = dayLabels;
			option.visualMap.min = minValue;
			option.visualMap.max = maxValue;
			option.series[0].name = pollutantTitle;
			option.series[0].data = formattedHeatmapData;
			if (option.toolbox && Object.hasOwn(option.toolbox, 'feature')) {
				option.toolbox.top = '1%';
				option.toolbox.right = '1%';
				if (Object.hasOwn(option.toolbox.feature, 'dataView')) {
					option['toolbox']['feature']['dataView']['title'] = this.$t('charts.title');
					option['toolbox']['feature']['dataView']['lang'] = [
						this.$t('charts.dataView'),
						this.$t('charts.closeView'),
						this.$t('charts.updateData')
					];
					option.toolbox.feature.dataView.optionToContent = (opt) => {
						let seriesData = opt.series[0].data;
						let seriesName = opt.series[0].name;
						seriesData.reverse();
						let categories = seriesData.map((item) => (item[0] < 10 ? '0' + item[0] : item[0]) + ':00');
						let ws_data = [...categories.map((category, index) => [seriesData[index][1], category, seriesData[index][2]])];
						ws_data.sort((a, b) => {
							if (a[1] < b[1]) {
								return -1;
							}
							if (a[1] > b[1]) {
								return 1;
							}
							return 0;
						});
						ws_data.sort((a, b) => {
							if (a[0] < b[0]) {
								return -1;
							}
							if (a[0] > b[0]) {
								return 1;
							}
							return 0;
						});

						let table =
							'<table style="width:100%;text-align:center;" class="data-view-table"><tbody><tr class="fw-bold">' +
							'<td>' +
							this.$t('rasterTools.date') +
							'</td>' +
							'<td>' +
							this.$t('panels.device.hour') +
							'</td>' +
							'<td>' +
							seriesName +
							'</td>' +
							'</tr>';
						ws_data.forEach((item) => {
							table +=
								'<tr>' +
								'<td>' +
								item[0] +
								'</td>' +
								'<td>' +
								item[1] +
								'</td>' +
								'<td>' +
								(item[2] !== null ? item[2] : '-') +
								'</td>' +
								'</tr>';
						});

						table += '</tbody></table>';
						return table;
					};
				}
				if (Object.hasOwn(option.toolbox.feature, 'restore')) {
					option['toolbox']['feature']['restore']['title'] = this.$t('charts.restore');
				}
				if (Object.hasOwn(option.toolbox.feature, 'saveAsImage')) {
					option['toolbox']['feature']['saveAsImage']['title'] = this.$t('charts.saveAsImage');
				}
			}
			return option;
		},
		initStackedSeries(pollutantAcronym, pollutantSymbol, historicPollutantData, elasticacronym, designOption = null) {
			const dayCount = this.form.dateInterval + 1;
			const hourLabels = Array.from({ length: 24 }, (_, i) => i);
			const dayLabels = Array.from({ length: dayCount }, (_, i) =>
				getDateByFormat(Date.now() - i * 24 * 60 * 60 * 1000, this.userProperties.constants, true)
			);
			let stackedData = {};
			let valueCount = {};
			dayLabels.forEach((day) => {
				valueCount[day] = Array(24).fill(0);
				stackedData[day] = Array(24).fill(0);
			});
			console.info(
				'debug historic data STACK',
				pollutantAcronym,
				pollutantSymbol,
				historicPollutantData,
				this.chartType,
				designOption.id,
				valueCount
			);
			if (historicPollutantData && typeof historicPollutantData.message !== 'string') {
				historicPollutantData.message.forEach((item) => {
					const date = new Date(item._source[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP]);
					const hour = date.getHours();
					const dayIndex = dayLabels.indexOf(getDateByFormat(date.valueOf(), this.userProperties.constants, true));
					const value = item._source[elasticacronym];
					if (dayIndex !== -1) {
						stackedData[dayLabels[dayIndex]][hour] += value;
						valueCount[dayLabels[dayIndex]][hour] += 1;
					}
				});
			}
			//Configuro opciones del grafico
			let option = designOption ? designOption.config : this.parameterConfiguration;

			option.legend = { data: dayLabels, top: '3%' };
			if (dayLabels.length > 10) option.legend.right = '8%';
			option.xAxis.data = hourLabels.map((hour) => {
				if (hour < 10) return '0' + hour + ':00';
				else return hour + ':00';
			});
			if (typeof option.tooltip.formatter == 'string') option.tooltip.formatter = eval(option.tooltip.formatter);
			option.series = [];
			dayLabels.forEach((day) => {
				option.series.push({
					name: day,
					type: 'line',
					stack: 'total',
					//tooltip: { valueFormatter: (value) => (value ? value + ' ' + pollutantSymbol : '-') },
					data: stackedData[day].map((hour, i) => (hour ? parseFloat((hour / valueCount[day][i]).toFixed(2)) : null))
				});
			});
			option.grid = {
				height: dayLabels.length > 10 ? '50%' : '60%',
				bottom: '23%',
				left: '4%',
				width: '87%'
			};
			if (option.toolbox && Object.hasOwn(option.toolbox, 'feature')) {
				option.toolbox.top = '3%';
				option.toolbox.right = '1%';
				if (Object.hasOwn(option.toolbox.feature, 'dataView')) {
					option['toolbox']['feature']['dataView']['title'] = this.$t('charts.title');
					option['toolbox']['feature']['dataView']['lang'] = [
						this.$t('charts.dataView'),
						this.$t('charts.closeView'),
						this.$t('charts.updateData')
					];
					option.toolbox.feature.dataView.optionToContent = (opt) => {
						const optionSeries = opt.series.filter((serie) => serie.type !== 'pie');
						let categories = Array.isArray(option.xAxis) ? option.xAxis[0].data : option.xAxis.data;
						let ws_data = [];

						optionSeries.forEach((serie) => {
							serie.data.forEach((value, i) => {
								ws_data.push([serie.name, categories[i], value]);
							});
						});

						ws_data.sort((a, b) => {
							if (a[1] < b[1]) {
								return -1;
							}
							if (a[1] > b[1]) {
								return 1;
							}
							return 0;
						});
						ws_data.sort((a, b) => {
							if (a[0] < b[0]) {
								return -1;
							}
							if (a[0] > b[0]) {
								return 1;
							}
							return 0;
						});

						let table =
							'<table style="width:100%;text-align:center;" class="data-view-table"><tbody><tr class="fw-bold">' +
							'<td>' +
							this.$t('rasterTools.date') +
							'</td>' +
							'<td>' +
							this.$t('panels.device.hour') +
							'</td>' +
							'<td>' +
							this.pollutant.name +
							'</td>' +
							'</tr>';

						ws_data.forEach((item) => {
							table +=
								'<tr>' +
								'<td>' +
								item[0] +
								'</td>' +
								'<td>' +
								item[1] +
								'</td>' +
								'<td>' +
								(item[2] !== null ? item[2] : '-') +
								'</td>' +
								'</tr>';
						});

						table += '</tbody></table>';
						return table;
					};
				}
				if (Object.hasOwn(option.toolbox.feature, 'restore')) {
					option['toolbox']['feature']['restore']['title'] = this.$t('charts.restore');
				}
				if (Object.hasOwn(option.toolbox.feature, 'saveAsImage')) {
					option['toolbox']['feature']['saveAsImage']['title'] = this.$t('charts.saveAsImage');
				}
			}
			return option;
		},
		initChartDatasource(pollutantAcronym, pollutantSymbol, elasticData, elasticacronym, designOption = null) {
			let pollutantValues = {};
			let pollutantDays = {};
			let pollutantData = {};
			let allDates = [];
			let averageSum = {};
			let averageCount = {};

			let minValue = 0;
			let maxValue = 0;
			let minValueDevice = '';
			let maxValueDevice = '';
			let minValueDate = '';
			let maxValueDate = '';
			let globalSum = 0;
			let globalCount = 0;
			let isCardLocation = this.displayLocation == 'card';

			console.log('initChartDatasource ', pollutantAcronym, elasticacronym, elasticData.message, designOption);
			if (elasticData && typeof elasticData.message !== 'string') {
				let documents = elasticData.message.map((hit) => hit._source);
				let documentsByDevice = this.groupByDevice(documents);
				if (Object.hasOwn(elasticData, 'aggs')) {
					minValue = parseFloat(elasticData.aggs['min_' + elasticacronym].value.toFixed(2));
					maxValue = parseFloat(elasticData.aggs['max_' + elasticacronym].value.toFixed(2));
				}

				globalCount = documents.length;
				documents.forEach((doc) => {
					if (!allDates.includes(getDateByFormat(doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties.constants)))
						allDates.push(getDateByFormat(doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties.constants));
					if (parseFloat(doc[elasticacronym].toFixed(2)) == minValue) {
						minValueDevice = doc.device;
						minValueDate = getDateByFormat(doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties.constants);
					}
					if (parseFloat(doc[elasticacronym].toFixed(2)) == maxValue) {
						maxValueDevice = doc.device;
						maxValueDate = getDateByFormat(doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties.constants);
					}
					globalSum += doc[elasticacronym];
					if (Object.hasOwn(averageSum, doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP])) {
						averageSum[doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP]] += doc[elasticacronym];
						averageCount[doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP]]++;
					} else {
						averageSum[doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP]] = doc[elasticacronym];
						averageCount[doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP]] = 1;
					}
				});
				console.log(
					'debug average serie data: ',
					averageSum,
					averageCount,
					Object.keys(averageSum).map((dateKey) => [
						parseInt(dateKey),
						parseFloat((averageSum[dateKey] / averageCount[dateKey]).toFixed(2))
					])
				);
				console.log('debug initChartDatasource', documents, documentsByDevice, allDates, minValueDevice, maxValueDevice);
				documentsByDevice.forEach((deviceGroup) => {
					pollutantDays[deviceGroup.device] = [];
					pollutantValues[deviceGroup.device] = [];
					pollutantData[deviceGroup.device] = [];
					deviceGroup.group.forEach((item) => {
						/* if (!allDates.includes(getDateByFormat(item[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties.constants)))
							allDates.push(getDateByFormat(item[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties.constants)); */
						pollutantDays[deviceGroup.device].push(
							getDateByFormat(item[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP], this.userProperties.constants)
						);
						pollutantValues[deviceGroup.device].push(parseFloat(item[elasticacronym].toFixed(2)));
						pollutantData[deviceGroup.device].push([
							item[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP],
							/* new Date(item[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP]).toString(), */
							parseFloat(item[elasticacronym].toFixed(2))
						]);
					});
				});

				documentsByDevice.forEach((deviceGroup) => {
					if (allDates.length != pollutantDays[deviceGroup.device].length) {
						allDates.forEach((date, i) => {
							if (!pollutantDays[deviceGroup.device].includes(date)) {
								pollutantDays[deviceGroup.device] = pollutantDays[deviceGroup.device].toSpliced(i, 0, date);
								pollutantValues[deviceGroup.device] = pollutantValues[deviceGroup.device].toSpliced(i, 0, null);
							}
						});
					}
				});
			}

			//Configuro opciones del grafico
			let option = designOption ? designOption.config : this.parameterConfiguration;
			console.info('info: chart option', option, designOption, this.parameterConfiguration, minValue, maxValue);

			//let pollutantTitle =pollutantSymbol && pollutantSymbol.trim().length > 0 ? pollutantAcronym + ' (' + pollutantSymbol + ')' : pollutantAcronym;
			this.metricAverage =
				(globalSum > 0
					? parseInt(globalSum / globalCount) === globalSum / globalCount
						? globalSum / globalCount
						: (globalSum / globalCount).toFixed(2)
					: '-') + (pollutantSymbol || '');
			this.metricMin = minValue + (pollutantSymbol || '') + ' (' + minValueDevice + ') - ' + minValueDate;
			this.metricMax = maxValue + (pollutantSymbol || '') + ' (' + maxValueDevice + ') - ' + maxValueDate;

			option['legend'] = {
				data: [this.$t('panels.notifications.average_operator')].concat(Object.keys(pollutantValues)),
				top: isCardLocation ? '3%' : '1%'
			};
			option.tooltip.formatter = (params) => {
				let dateLabel = getDateByFormat(params[0].axisValue, this.userProperties.constants);
				let element = '<div class="pa-0"><span class="pb-2">' + dateLabel + '</span>';
				params.forEach((seriesParams) => {
					element +=
						'<div class="d-flex justify-space-between"><span class="pr-2">' +
						seriesParams.marker +
						' ' +
						seriesParams.seriesName +
						'</span><b>' +
						seriesParams.data[1] +
						' ' +
						(pollutantSymbol ? pollutantSymbol : '') +
						'</b></div>';
				});

				element += '</div>';

				return element;
			};
			option.series = [];
			if (Object.keys(pollutantValues).length > 1) {
				option.series.push({
					name: this.$t('panels.notifications.average_operator'),
					id: 'avg',
					type: 'line',
					smooth: false,
					emphasis: {
						focus: 'series'
					},
					data: Object.keys(averageSum).map((dateKey) => [
						parseInt(dateKey),
						parseFloat((averageSum[dateKey] / averageCount[dateKey]).toFixed(2))
					])
				});
			}

			Object.keys(pollutantValues).forEach((device) => {
				option.series.push({
					name: device,
					id: device,
					type: 'line',
					smooth: false,
					emphasis: {
						focus: 'series'
					},
					data: pollutantData[device]
				});
			});

			option.xAxis = {
				type: 'time', // Eje X de tipo tiempo
				boundaryGap: false,
				splitLine: {
					show: true
				}
			};
			option.yAxis = {
				type: 'value',
				splitLine: {
					show: true
				}
			};

			/* if (Object.hasOwn(option, 'xAxis')) option['xAxis']['data'] = allDates; */
			console.log('debug min and max', minValue, maxValue, minValueDevice, maxValueDevice, option.series[0].name);
			/* option.series.forEach((serie) => {
				if (minValueDevice == serie.name) {
					if (Object.hasOwn(serie, 'markPoint')) {
						serie.markPoint.data
							? serie.markPoint.data.push({ type: 'min', name: 'Min' })
							: (serie.markPoint.data = [{ type: 'min', name: 'Min' }]);
					} else {
						serie.markPoint = {
							data: [{ type: 'min', name: 'Min' }],
							symbol: 'pin',
							symbolSize: 50
						};
					}
				}

				if (maxValueDevice == serie.name) {
					if (Object.hasOwn(serie, 'markPoint')) {
						serie.markPoint.data
							? serie.markPoint.data.push({ type: 'max', name: 'Max' })
							: (serie.markPoint.data = [{ type: 'max', name: 'Max' }]);
					} else {
						serie.markPoint = {
							data: [{ type: 'max', name: 'Max' }],
							symbol: 'pin',
							symbolSize: 50
						};
					}
				}
			}); */

			if (option.series.length == 1) {
				option.series[0].markLine = {
					data: [{ type: 'average', name: 'Avg' }]
				};
			}

			if (option.toolbox && Object.hasOwn(option.toolbox, 'feature')) {
				option.toolbox.top = '3%';
				option.toolbox.right = '1%';
				if (Object.hasOwn(option.toolbox.feature, 'dataView')) {
					option['toolbox']['feature']['dataView']['title'] = this.$t('charts.title');
					option['toolbox']['feature']['dataView']['lang'] = [
						this.$t('charts.dataView'),
						this.$t('charts.closeView'),
						this.$t('charts.updateData')
					];

					option.toolbox.feature.dataView.optionToContent = this.setOptionToContentDatasource(
						allDates,
						pollutantValues,
						pollutantSymbol || ''
					);
				}
				if (Object.hasOwn(option.toolbox.feature, 'restore')) {
					option['toolbox']['feature']['restore']['title'] = this.$t('charts.restore');
				}
				if (Object.hasOwn(option.toolbox.feature, 'saveAsImage')) {
					option['toolbox']['feature']['saveAsImage']['title'] = this.$t('charts.saveAsImage');
				}
			}

			option['series'][0].type = this.chartType;
			//console.log('show intervals init', this.form.showIntervals, option);
			if (this.form.showIntervals) {
				option['series'][0].markArea = {
					silent: true,
					data: [[{ yAxis: this.pollutant.thresholdminvalue }, { yAxis: this.pollutant.thresholdmaxvalue }]],
					itemStyle: { borderType: 'dashed', borderWidth: 3, borderColor: 'red' }
				};
			} else {
				if (Object.hasOwn(option['series'][0], 'markArea')) delete option['series'][0].markArea;
			}
			option['textStyle'] = {
				fontFamily: 'Montserrat, sans-serif',
				fontSize: 14
			};
			option.grid = {
				height: isCardLocation ? '60%' : '64%',
				bottom: isCardLocation ? '25%' : '21%',
				left: '4%',
				width: '87%'
			};
			console.log('set historic option: ', this.displayLocation, option, option.series[0].data.length);
			this.advancedProps = { categories: allDates, values: pollutantValues, avg: option.series[0].data };
			return option;
		},
		getStatusInRange(data, parameterStatus) {
			let activeStatus = getStatusProperties(parameterStatus, data.value);
			return activeStatus ? activeStatus.name : '-';
		},
		setOptionToContent(visualization) {
			let optionToContent = null;
			if (
				visualization == constants.VISUALIZATIONS.TIME_SERIES ||
				visualization == constants.VISUALIZATIONS.HISTOGRAM ||
				visualization == constants.VISUALIZATIONS.AREA_CHART
			) {
				optionToContent = (opt) => {
					try {
						const parameterStatus = this.pollutant
							? this.$store.getters.getParameterStatus.find((status) => status.pmparameterid === this.pollutant.pmparameterid)
							: false;
						let seriesData = opt.series[0].data;
						let seriesName = opt.series[0].name;
						let categories = Array.isArray(opt.xAxis) ? opt.xAxis[0].data : opt.xAxis.data;
						let ws_data = [];
						let table = '';
						if (!parameterStatus) {
							ws_data = [...categories.map((category, index) => [category, seriesData[index].value])];
							table =
								'<table style="width:100%;text-align:center;" class="data-view-table"><tbody><tr class="fw-bold">' +
								'<td>' +
								this.$t('rasterTools.date') +
								'</td>' +
								'<td>' +
								seriesName +
								'</td>' +
								'</tr>';
							ws_data.forEach((item) => {
								table += '<tr>' + '<td>' + item[0] + '</td>' + '<td>' + item[1] + '</td>' + '</tr>';
							});
						} else {
							ws_data = [
								...categories.map((category, index) => [
									category,
									seriesData[index].value,
									this.getStatusInRange(seriesData[index], parameterStatus),
									seriesData[index].itemStyle.color
								])
							];
							table =
								'<table style="width:100%;text-align:center;" class="data-view-table"><tbody><tr class="fw-bold">' +
								'<td>' +
								this.$t('rasterTools.date') +
								'</td>' +
								'<td>' +
								seriesName +
								'</td>' +
								'<td>' +
								this.$t('form.pmstatus') +
								'</td>' +
								'<td>' +
								this.$t('pmstatus.color') +
								'</td>' +
								'</tr>';
							ws_data.forEach((item) => {
								table +=
									'<tr>' +
									'<td>' +
									item[0] +
									'</td>' +
									'<td>' +
									(item[1] !== null ? item[1] : '-') +
									'</td>' +
									'<td>' +
									item[2] +
									'</td>' +
									'<td style="color: ' +
									item[3] +
									'">' +
									item[3] +
									'</td>' +
									'</tr>';
							});
						}

						table += '</tbody></table>';
						return table;
					} catch (error) {
						console.error('ERROR: optionToContent', error);
						return;
					}
				};
			} else if (visualization == constants.VISUALIZATIONS.FREQUENCY_HISTOGRAM) {
				optionToContent = (opt) => {
					console.info('debug freq opt', opt, opt.xAxis);
					try {
						let seriesData = opt.series[0].data;
						let categories = Array.isArray(opt.xAxis) ? opt.xAxis[0].data : opt.xAxis.data;
						let headerRow = [this.$t('panels.notifications.interval'), this.$t('map.items')];
						let ws_data = [...categories.map((category, index) => [category, seriesData[index]])];
						let table =
							'<table style="width:100%;text-align:center;" class="data-view-table"><tbody><tr class="fw-bold">' +
							'<td>' +
							headerRow[0] +
							'</td>' +
							'<td>' +
							headerRow[1] +
							'</td>' +
							'</tr>';
						ws_data.forEach((item) => {
							table += '<tr>' + '<td>' + item[0] + '</td>' + '<td>' + item[1] + '</td>' + '</tr>';
						});

						table += '</tbody></table>';
						return table;
					} catch (error) {
						console.error('ERROR: optionToContent', error);
						return;
					}
				};
			} else if (visualization == constants.VISUALIZATIONS.FREQUENCY_PIE) {
				optionToContent = (opt) => {
					try {
						let seriesData = opt.series[0].data;
						let categories = seriesData.map((item) => item.name);
						let headerRow = [this.$t('panels.notifications.interval'), this.$t('map.items')];
						let ws_data = [...categories.map((category, index) => [category, seriesData[index].value])];
						let table =
							'<table style="width:100%;text-align:center;" class="data-view-table"><tbody><tr class="fw-bold">' +
							'<td>' +
							headerRow[0] +
							'</td>' +
							'<td>' +
							headerRow[1] +
							'</td>' +
							'</tr>';
						ws_data.forEach((item) => {
							table += '<tr>' + '<td>' + item[0] + '</td>' + '<td>' + item[1] + '</td>' + '</tr>';
						});

						table += '</tbody></table>';
						return table;
					} catch (error) {
						console.error('ERROR: optionToContent', error);
						return;
					}
				};
			}
			return optionToContent;
		},
		setOptionToContentDatasource(allDates, pollutantValues, unit = '') {
			return (opt) => {
				const optionSeries = opt.series.filter((serie) => serie.type !== 'pie');
				let categories = [];
				categories = allDates;
				let headerRow = [this.$t('rasterTools.date')];
				optionSeries.forEach((serie) => headerRow.push(serie.name));
				let ws_data = [];
				if (optionSeries.length > 1) {
					ws_data = [
						...categories.map((category, index) => {
							let row = [category];
							optionSeries.forEach((serie) => {
								if (serie.id == 'avg') {
									row.push(serie.data[index][1]);
								} else {
									row.push(pollutantValues[serie.name][index]);
								}
							});
							return row;
						})
					];
				} else {
					ws_data = [
						...categories.map((category, index) => {
							let row = [category];
							optionSeries.forEach((serie) => row.push(pollutantValues[serie.name][index]));
							return row;
						})
					];
				}
				let table = '<table style="width:100%;text-align:center;" class="data-view-table mb-4"><tbody><tr class="fw-bold">';
				headerRow.forEach((item) => (table += '<td class="px-1">' + item + '</td>'));
				table += '</tr>';

				ws_data.forEach((row) => {
					table += '<tr>';
					row.forEach((item, i) => (table += '<td>' + (i == 0 ? item : item && item >= 0 ? item + ' ' + unit : '-') + '</td>'));
					table += '</tr>';
				});

				table += '</tbody></table>';
				return table;
			};
		},
		groupByDevice(dataset) {
			const groupedDevices = dataset.reduce((r, { device, ...rest }) => {
				if (!r[device]) {
					r[device] = { device, group: [rest] };
				} else {
					r[device].group.push(rest);
				}
				return r;
			}, {});
			return Object.values(groupedDevices).sort((a, b) => (a.device > b.device ? 1 : b.device > a.device ? -1 : 0));
		}
	}
};
