<template>
	<div class="mappanelinfo d-flex" ref="mappanelinfo" style="overflow: hidden">
		<!-- Info Panels -->

		<v-card elevation="2" class="mappaneldetails" :style="{ display: collapsed ? 'none' : 'block' }">
			<transition name="scroll-x-transition" mode="out-in" :duration="100">
				<component v-if="panelType == 'notifications-map-panel-grouped'" :is="panelType" :key="notificationsKey" />
				<component v-else :is="panelType" />
			</transition>
		</v-card>

		<!-- Tabs -->
		<div id="div-mappaneltabs" class="mappaneltabs d-flex flex-column fill-height pt-2" style="z-index: 1">
			<!-- Tab Collapse -->
			<v-tooltip v-if="showCollapse" right :color="primaryColor + 'cc'" transition="scroll-x-transition">
				<template v-slot:activator="{ on, attrs }">
					<div
						class="tab tabcollapse d-flex justify-center align-center"
						@click="collapsed = !collapsed"
						:title="collapsed ? $t('common.expand') : $t('common.collapse')"
						v-bind="attrs"
						v-on="on"
					>
						<v-icon small color="var(--gris-primary-active)">
							far {{ collapsed ? 'fa-angle-double-right' : 'fa-angle-double-left' }}</v-icon
						>
					</div>
				</template>
				<span class="fs-regular">{{ collapsed ? $t('common.expand') : $t('common.collapse') }}</span>
			</v-tooltip>

			<!-- Tab Settings -->
			<v-tooltip v-if="showSettings" right :color="primaryColor + 'cc'" transition="scroll-x-transition">
				<template v-slot:activator="{ on, attrs }">
					<div
						class="tab tabsettings d-flex justify-center align-center"
						:class="activeTabSettings"
						@click="showTabSettings"
						:title="$t('panels.configpannel.mapconfig')"
						v-bind="attrs"
						v-on="on"
					>
						<v-icon small :color="isActiveTabSettings ? primaryColor : 'var(--gris-primary-active)'">fas fa-sliders-v</v-icon>
					</div>
				</template>
				<span class="fs-regular">{{ $t('panels.configpannel.mapconfig') }}</span>
			</v-tooltip>

			<!-- Tab Notifications In Situ -->
			<v-tooltip v-if="showNotifications" right :color="primaryColor + 'cc'" transition="scroll-x-transition">
				<template v-slot:activator="{ on, attrs }">
					<div
						class="tab tabnotifications d-flex justify-center align-center"
						:class="activeTabNotifications"
						@click="showTabNotifications"
						:title="$t('common.notifications')"
						v-bind="attrs"
						v-on="on"
					>
						<v-icon small :color="isActiveTabNotifications ? primaryColor : 'var(--gris-primary-active)'"
							>fak fa-line-notification</v-icon
						>
					</div>
				</template>
				<span class="fs-regular">{{ $t('common.notifications') }}</span>
			</v-tooltip>

			<!-- Tab Devices / Sensors -->
			<v-tooltip v-if="showDevices" right :color="primaryColor + 'cc'" transition="scroll-x-transition">
				<template v-slot:activator="{ on, attrs }">
					<div
						class="tab tabdevices d-flex justify-center align-center"
						:class="activeTabDevices"
						@click="showTabDevices"
						:title="$t('common.devices')"
						v-bind="attrs"
						v-on="on"
					>
						<v-icon small :color="isActiveTabDevices ? primaryColor : 'var(--gris-primary-active)'">fak fa-line-sensor</v-icon>
					</div>
				</template>
				<span class="fs-regular">{{ $t('common.devices') }}</span>
			</v-tooltip>

			<!-- Tab AQI Points -->
			<v-tooltip v-if="showAqiPoints" right :color="primaryColor + 'cc'" transition="scroll-x-transition">
				<template v-slot:activator="{ on, attrs }">
					<div
						class="tab tabaqipoints d-flex justify-center align-center"
						:class="activeTabAqiPoints"
						@click="showTabAqiPoints"
						:title="$t('common.aqipoints')"
						v-bind="attrs"
						v-on="on"
					>
						<v-icon small :color="isActiveTabAqiPoints ? primaryColor : 'var(--gris-primary-active)'">fak fa-aqi</v-icon>
					</div>
				</template>
				<span class="fs-regular">{{ $t('common.aqipoints') }}</span>
			</v-tooltip>

			<!-- Tab Compare Maps -->
			<v-tooltip right :color="primaryColor + 'cc'" transition="scroll-x-transition">
				<template v-slot:activator="{ on, attrs }">
					<div
						class="tab tabaqipoints d-flex justify-center align-center"
						:class="activeTabCompareMaps"
						@click="showTabCompareMaps"
						:title="$t('common.comparemaps')"
						v-bind="attrs"
						v-on="on"
					>
						<v-icon small :color="isActiveTabCompareMaps ? primaryColor : 'var(--gris-primary-active)'">fak fa-split-screen</v-icon>
					</div>
				</template>
				<span class="fs-regular">{{ $t('common.comparemaps') }}</span>
			</v-tooltip>

			<!-- Dynamic Tabs -->
			<dynamic-tabs />
		</div>
	</div>
</template>

<script>
import SvgIcon from '@/components/map/icon/SvgIcon';
import { SETTINGS_PANEL_ID, NOTIFICATIONS_PANEL_ID, DEVICES_PANEL_ID, AQIPOINTS_PANEL_ID, COMPAREMAPS_PANEL_ID } from '@/lib/variables/panels';
import DynamicTabs from './dynamics/DynamicTabs';
import ol2map from '@/components/map/sections/map/subcomponents/ol2map';

import { getIconsTypeIcon } from '@/api/common';
import constants from '@/utils/constants';

import { NOTIFICATIONS_LAYER } from '../../../../lib/constants/layers';

const SettingsMapPanel = () => import('@/components/map/sections/info/settings/SettingsMapPanel');
const DevicesMapPanel = () => import('@/components/map/sections/info/settings/DevicesMapPanel');
const AqiPointsMapPanel = () => import('@/components/map/sections/info/settings/AqiPointsMapPanel');
const CompareMapsMapPanel = () => import('@/components/map/sections/info/settings/CompareMapsMapPanel');
const NotificationsMapPanelGrouped = () => import('@/components/map/sections/info/notifications/NotificationsMapPanelGrouped');
const DynamicPanels = () => import('./dynamics/DynamicPanels');

export default {
	name: 'mappanelinfo',

	mixins: [],

	components: {
		SvgIcon,
		SettingsMapPanel,
		DevicesMapPanel,
		AqiPointsMapPanel,
		CompareMapsMapPanel,
		NotificationsMapPanelGrouped,
		DynamicPanels,
		DynamicTabs
	},

	props: {
		selectedtab: {
			type: String,
			required: false,
			default: SETTINGS_PANEL_ID
		}
	},
	data() {
		return {
			showNotifications: false,
			notificationsKey: 0,
			debugNotifications: false,
			primaryColor: this.$store.state.global.primaryColor,
			showCollapse: false
		};
	},
	computed: {
		isActiveTabCompareMaps() {
			return this.$store.getters.activePanel === COMPAREMAPS_PANEL_ID;
		},

		showSettings() {
			//console.log("showSettings", !this.isActiveTabCompareMaps)
			return true /* !this.isActiveTabCompareMaps */;
			//return Object.values(this.$store.getters.getDevicesData).filter((device) => device.spatialmodel != constants.SATELLITE_MODEL).length > 0;
		},
		showActiveTabNotifications() {
			return this.userProperties.shownotifications /* && !this.isActiveTabCompareMaps */;
			//return Object.values(this.$store.getters.getDevicesData).filter((device) => device.spatialmodel != constants.SATELLITE_MODEL).length > 0;
		},
		showDevices() {
			return this.$store.getters.getHasDevices /* && !this.isActiveTabCompareMaps */;
			//return Object.values(this.$store.getters.getDevicesData).filter((device) => device.spatialmodel != constants.SATELLITE_MODEL).length > 0;
		},
		showAqiPoints() {
			return this.$store.getters.getHasAqiPoints && this.userProperties.showaqi /* && !this.isActiveTabCompareMaps */;
		},
		showCompareMaps() {
			return !!this.userProperties.usesatelliteimages;
		},
		panelType() {
			const map = {
				[NOTIFICATIONS_PANEL_ID]: 'notifications-map-panel-grouped',
				[SETTINGS_PANEL_ID]: 'settings-map-panel',
				[DEVICES_PANEL_ID]: 'devices-map-panel',
				[AQIPOINTS_PANEL_ID]: 'aqi-points-map-panel',
				[COMPAREMAPS_PANEL_ID]: 'compare-maps-map-panel'
			};

			//console.log(this.activePanel)
			//console.log(map[this.activePanel])

			return map[this.activePanel] || 'dynamic-panels';
		},

		activePanel() {
			return this.$store.getters.activePanel;
		},

		isActiveTabSettings() {
			return this.$store.getters.activePanel === SETTINGS_PANEL_ID;
		},

		isActiveTabNotifications() {
			return this.$store.getters.activePanel === NOTIFICATIONS_PANEL_ID;
		},

		isActiveTabDevices() {
			return this.$store.getters.activePanel === DEVICES_PANEL_ID;
		},
		isActiveTabAqiPoints() {
			return this.$store.getters.activePanel === AQIPOINTS_PANEL_ID;
		},

		activeTabSettings() {
			return {
				tabactive: this.isActiveTabSettings
			};
		},

		activeTabNotifications() {
			return {
				tabactive: this.isActiveTabNotifications
			};
		},

		activeTabDevices() {
			return {
				tabactive: this.isActiveTabDevices
			};
		},
		activeTabAqiPoints() {
			return {
				tabactive: this.isActiveTabAqiPoints
			};
		},
		activeTabCompareMaps() {
			return {
				tabactive: this.isActiveTabCompareMaps
			};
		},
		tabNotificationIcon() {
			return getIconsTypeIcon(constants.NOTIFICATIONS_ICONS_TYPE, constants.NOTIFICATIONS_TAB_ACRONYM, this.$store.getters.getIcons).route;
		},
		tabNotificationSelectedIcon() {
			return getIconsTypeIcon(constants.NOTIFICATIONS_ICONS_TYPE, constants.NOTIFICATIONS_SELECTED_ACRONYM, this.$store.getters.getIcons).route;
		},
		featuresNotifications() {
			return this.$store.state.data[NOTIFICATIONS_LAYER].viewportFeatures;
		},
		notificationsData() {
			return this.$store.state.data[NOTIFICATIONS_LAYER];
		},

		featureClick: {
			get() {
				return this.$store.getters.getFeatureClick;
			},
			set(value) {
				this.$store.commit('setFeatureClick', value);
			}
		},
		displayMode: {
			get() {
				return this.$store.getters.getDisplayMode;
			},
			set(value) {
				this.$store.commit('setDisplayMode', value);
			}
		},
		collapsed: {
			get() {
				return this.$store.getters.getCollapsedInfo;
			},
			set(value) {
				this.$store.commit('setCollapsedInfo', value);
			}
		}
	},
	watch: {},
	beforeCreate() {
		this.$store.commit('setActivePanel', { panelId: SETTINGS_PANEL_ID });
		this.$store.commit('setPanelsClosed', false);
	},
	beforeMount() {
		//console.log('beforemount mappanelinfo');
		//console.log(this.showAqiPoints);
		this.showNotifications = !!this.userProperties.shownotifications;
	},
	mounted() {
		//console.log('mounted mappanelinfo', this.featuresNotifications);
	},
	updated() {
		//console.log('updated mappanelinfo', this.featuresNotifications);
		/*if ( this.$store.getters.activePanel == COMPAREMAPS_PANEL_ID) {
			console.log('COMPAREMAPS_PANEL_ID',  this.$store.getters.activePanel);
			const mapRef = this.$parent.$refs.map;
			console.log(this.$parent.$refs);

			if (mapRef) {
				// Cambiar el ancho al 50%
				mapRef.style.width = '50%';
			}
		}*/
	},
	methods: {
		adjustMapContainerWidth(display) {
			var divMapContainer = document.getElementById('div-map-compare-container');
			if (divMapContainer) {
				divMapContainer.style.display = display;
			}
		},
		showTabSettings() {
			if (this.isActiveTabCompareMaps) this.closeCompareMapPanel();
			this.showTab(SETTINGS_PANEL_ID);
		},

		showTabNotifications() {
			if (this.isActiveTabCompareMaps) this.closeCompareMapPanel();
			this.showTab(NOTIFICATIONS_PANEL_ID);
		},

		showTabDevices() {
			if (this.isActiveTabCompareMaps) this.closeCompareMapPanel();
			this.showTab(DEVICES_PANEL_ID);
		},
		showTabAqiPoints() {
			if (this.isActiveTabCompareMaps) this.closeCompareMapPanel();
			this.showTab(AQIPOINTS_PANEL_ID);
		},
		showTabCompareMaps() {
			const mapContainers = document.querySelectorAll('.map-container');
			mapContainers.forEach((container) => {
				container.style.marginRight = '22px';
			});

			if (this.$store.state.mappanel.rasterLayersSettings !== null) {
				const activeLayerSettings = Object.keys(this.$store.state.mappanel.rasterLayersSettings).find(
					(layer) => this.$store.state.mappanel.rasterLayersSettings[layer]['active']
				);
				if (activeLayerSettings !== undefined) {
					this.$store.state.mappanel.rasterLayersSettings[activeLayerSettings]['active'] = false;
					ol2map.setVisibility(activeLayerSettings, false);
				}
			}

			this.featureClick = null;
			document.documentElement.style.setProperty('--map-control-bottom-position', '0px');
			this.displayMode = 'card';

			this.$store.commit('cleanFormHistoric');
			this.$store.commit('cleanFormAdvancedChart');
			this.$store.commit('cleanFormCompareAqi');
			this.$store.commit('cleanCompareAqiElasticData');
			this.$store.commit('setFeaturePopupClick', false);

			this.adjustMapContainerWidth('block');
			this.showTab(COMPAREMAPS_PANEL_ID);
			//console.log(" this.$store.getters.activePanel", this.$store.getters.activePanel);
		},
		showTab(panelId) {
			this.$store.commit('setActivePanel', { panelId });
			if (panelId !== COMPAREMAPS_PANEL_ID) {
				this.adjustMapContainerWidth('none');
			}
		},
		closeCompareMapPanel() {
			const mapContainers = document.querySelectorAll('.map-container');
			mapContainers.forEach((container) => {
				container.style.marginRight = '0px';
			});

			if (this.$store.state.mappanel.rasterLayersCompareMap1 !== null) {
				const activeLayerCompareMap1 = Object.keys(this.$store.state.mappanel.rasterLayersCompareMap1).find(
					(layer) => this.$store.state.mappanel.rasterLayersCompareMap1[layer]['active']
				);
				if (activeLayerCompareMap1 !== undefined) {
					this.$store.state.mappanel.rasterLayersCompareMap1[activeLayerCompareMap1]['active'] = false;
					ol2map.setVisibility(activeLayerCompareMap1, false);
				}
			}
			if (this.$store.state.mappanel.rasterLayersCompareMap2 !== null) {
				const activeLayerCompareMap2 = Object.keys(this.$store.state.mappanel.rasterLayersCompareMap2).find(
					(layer) => this.$store.state.mappanel.rasterLayersCompareMap2[layer]['active']
				);
				if (activeLayerCompareMap2 !== undefined) {
					this.$store.state.mappanel.rasterLayersCompareMap2[activeLayerCompareMap2]['active'] = false;
					ol2map
						.getMapCompareInstance()
						.getLayers()
						.forEach(function (layer) {
							if (layer.get('id') === activeLayerCompareMap2) {
								layer.setVisible(false);
							}
						});
				}
			}
			this.$store.commit('setIsRasterCompareMap1Active', false);
			this.$store.commit('setIsRasterCompareMap2Active', false);
			this.$store.commit('setIsRasterActive', false);

			this.$store.state.settingsPanel.isOpened.layersrasterSettings = false;
			this.$store.state.compareMapPanel.isOpened.layersrasterCompareMap1 = false;
			this.$store.state.compareMapPanel.isOpened.layersrasterCompareMap2 = false;

			var divMapContainer = document.getElementById('div-map-compare-container');
			if (divMapContainer) {
				divMapContainer.style.display = 'none';
			}
		}
	}
};
</script>

<style lang="postcss">
@import '../../../../styles/eiffel-variables.pcss';

.mappanelinfo {
	width: 100%;
	height: 100%;
}

.mappaneldetails {
	position: relative;
	width: calc(100% - 42px);
	background-color: var(--fondo-1) !important;
	box-shadow: 2px 0 10px 0 rgba(49, 59, 111, 0.31);
	border-right: 1px #ccc solid !important;
}

.mappaneltabs {
	background-color: transparent;
	width: 42px;
	height: calc(100vh - 48px);
	float: right;
	//margin-left: max(318px, 20vw);
	//position: absolute;

	& .tab {
		width: 40px;
		height: 40px;
		background-color: var(--fondo-1);
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		border-bottom: 1px solid #bbb;
		color: var(--primary);
		margin-top: 1px;
		margin-bottom: 1px;

		&.tabactive {
			background-color: var(--fondo-1);

			i {
				color: var(--azul-primary-100);
			}
		}

		&:hover {
			cursor: pointer;
		}

		& .v-icon {
			font-size: 1.25rem !important;
			font-weight: 500 !important;
		}
	}
}

.layerslist .subheader .selector:hover {
	font-weight: 700 !important;
}
</style>
