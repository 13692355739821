<template>
	<div :id="id" class="ol-popup pa-0" style="z-index: 0">
		<v-card v-if="feature" elevation="3" class="pa-0">
			<div class="card-title d-flex justify-space-between px-3 py-2">
				<v-card-title class="card-title-text fw-bold align-center ma-0 pa-0" v-if="feature.code">
					{{ feature.code }}
				</v-card-title>
				<div v-if="notificationsByType" class="d-flex align-center">
					<v-tooltip
						top
						transition="none"
						:color="notificationType.severitycolor + 'd9'"
						v-for="(notificationType, i) in notificationsByType"
						:key="i"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-chip
								color="white"
								class="px-2 mr-1"
								link
								pill
								small
								v-bind="attrs"
								v-on="on"
								@click="openActiveNotifications(feature)"
							>
								<!-- <svg-notification :fill="primaryColor" :size="18"></svg-notification> -->
								<v-img :src="getNotificationIcon(notificationType.iconroute)" height="24" aspect-ratio="1" contain />
								<span class="ps-2 fs-regular fw-500">{{ notificationType.notifications.length }}</span></v-chip
							>
						</template>
						<span class="fs-regular">{{ notificationType.severityname }}</span>
					</v-tooltip>
				</div>
				<!-- <v-badge offset-x="0" offset-y="12" color="green" :content="4"></v-badge> -->
				<!-- <v-card-subtitle class="pa-0 ma-0">
					<p class="card-subtitle-text fw-regular d-flex justify-start ma-0 pa-0" v-if="feature.latitude">
						{{ feature.longitude }} N, {{ feature.latitude }}E
					</p>
				</v-card-subtitle> -->
			</div>

			<v-list v-if="popupElasticData && typeof popupElasticData == 'object'" lines="one" density="compact" class="date-container py-0 px-3">
				<v-tooltip
					left
					v-if="parametersEarly.length > 0 || parametersLate.length > 0"
					max-width="300"
					nudge-left="7"
					nudge-bottom="45"
					class="d-flex justify-start"
					transition="scroll-x-transition"
					color="00000099"
				>
					<template v-slot:activator="{ on, attrs }">
						<div class="d-flex justify-start" style="cursor: pointer" v-bind="attrs" v-on="on">
							<v-icon size="14" class="fw-500 pr-2" style="color: var(--rojo-100) !important">fak fa-solid-hour</v-icon>
							<span class="date-text fw-regular fs-sm ma-0 px-0 py-1">
								{{ getElasticSearchDate() }}
							</span>
						</div>
					</template>
					<div>
						<div v-if="parametersEarly.length > 0">
							<span class="fs-regular">{{ parametersEarly[0].message }}:</span>
							<ul class="fs-regular">
								<li v-for="(val, i) in parametersEarly" :key="i">
									<span v-if="getParameterName(val.parameter)">{{ getParameterName(val.parameter) }}</span>
									<span v-else>{{ val.parameter }}</span>
								</li>
							</ul>
						</div>
						<div v-if="parametersLate.length > 0">
							<span class="fs-regular">{{ parametersLate[0].message }}:</span>
							<ul>
								<li v-for="(val, i) in parametersLate" :key="i">
									<span v-if="getParameterName(val.parameter)">{{ getParameterName(val.parameter) }}</span>
									<span v-else>{{ val.parameter }}</span>
								</li>
							</ul>
						</div>
					</div>
				</v-tooltip>
				<div v-else class="d-flex justify-start">
					<v-icon size="14" class="fw-500 pr-2">fak fa-solid-hour</v-icon>
					<span class="date-text fw-regular fs-sm ma-0 px-0 py-1">
						{{ getElasticSearchDate() }}
					</span>
				</div>
			</v-list>
			<v-container class="bg-surface-variant pa-0 px-2" v-if="isParameters && popupElasticData && typeof popupElasticData == 'object'">
				<v-row no-gutters class="pa-1" v-for="(parameter, i) in parameters" :key="i">
					<!-- Parameter NAME -->
					<v-tooltip left v-if="parameter.name.length > 18" nudge-left="5" transition="scroll-x-transition" color="00000099">
						<template v-slot:activator="{ on, attrs }">
							<v-col
								cols="7"
								class="d-flex flex-nowrap justify-start align-center pa-0"
								style="cursor: pointer"
								v-bind="attrs"
								v-on="on"
							>
								<span class="fw-regular fs-regular"
									>{{ parameter.acronym }} <i class="fak fa-line-info-2 ps-1 fs-sm fw-regular"></i
								></span>
							</v-col>
						</template>
						<span class="fs-regular">{{ parameter.name }}</span>
					</v-tooltip>
					<v-col v-else cols="7" class="d-flex flex-nowrap justify-start align-center pa-0">
						<span class="fw-regular fs-regular">{{ parameter.name }}</span>
					</v-col>
					<!-- Parameter VALUE -->
					<v-col
						v-if="getParameterErrors(parameter.elasticacronym).length > 0 && statusProperties[parameter.elasticacronym]"
						cols="5"
						class="d-flex flex-nowrap justify-space-between align-center pa-0"
					>
						<v-row no-gutters>
							<v-tooltip right nudge-right="25" transition="scroll-x-transition" color="00000099">
								<template v-slot:activator="{ on, attrs }">
									<v-col
										cols="10"
										class="pollutant-value pa-0 d-flex justify-center align-center"
										:id="parameter.elasticacronym + '-value'"
										style="cursor: pointer"
										v-bind="attrs"
										v-on="on"
									>
										<span v-if="parameter.showstatusname == 1" class="fw-semi fs-regular">
											{{ statusProperties[parameter.elasticacronym].name }}
										</span>
										<span v-else class="fw-semi fs-regular text-center">{{ parameterValues[parameter.elasticacronym] }}</span>
									</v-col>
								</template>
								<span v-if="parameter.showstatusname == 1" class="fw-500 fs-regular">
									{{ parameterValues[parameter.elasticacronym] }}
								</span>
								<span v-else class="fw-500 fs-regular text-center">{{ statusProperties[parameter.elasticacronym].name }}</span>
							</v-tooltip>
							<v-tooltip right nudge-right="5" max-width="350" transition="scroll-x-transition" color="00000099">
								<template v-slot:activator="{ on, attrs }">
									<v-col cols="2" class="pa-0" v-bind="attrs" v-on="on">
										<v-icon class="ps-1 fs-sm fw-regular" style="color: var(--rojo-100) !important">fak fa-solid-alert</v-icon>
									</v-col>
								</template>
								<ul v-if="getParameterErrors(parameter.elasticacronym).length > 1">
									<li
										v-for="(error, i) in getParameterErrors(parameter.elasticacronym)"
										:key="i"
										class="fw-500 fs-regular text-center"
									>
										{{ error.message }}
									</li>
								</ul>
								<span v-else-if="getParameterErrors(parameter.elasticacronym)[0].info" class="fs-regular">
									{{
										getParameterErrors(parameter.elasticacronym)[0].message +
										'. ' +
										getParameterErrors(parameter.elasticacronym)[0].info
									}}
								</span>
								<span v-else class="fs-regular">{{ getParameterErrors(parameter.elasticacronym)[0].message }}</span>
							</v-tooltip>
						</v-row>
					</v-col>
					<v-tooltip
						right
						v-else-if="getParameterErrors(parameter.elasticacronym).length > 0"
						nudge-right="5"
						max-width="350"
						transition="scroll-x-transition"
						color="00000099"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-col
								cols="5"
								:id="parameter.elasticacronym + '-value'"
								class="d-flex flex-nowrap justify-center align-center pollutant-value pa-0"
								style="cursor: pointer"
								v-bind="attrs"
								v-on="on"
							>
								<span class="fw-semi fs-regular text-center"
									>{{ parameterValues[parameter.elasticacronym] }}
									<i class="fak fa-solid-alert ps-1 fs-sm fw-regular" style="color: var(--rojo-100) !important"></i
								></span>
							</v-col>
						</template>
						<ul v-if="getParameterErrors(parameter.elasticacronym).length > 1">
							<li v-for="(error, i) in getParameterErrors(parameter.elasticacronym)" :key="i" class="fw-500 fs-regular text-center">
								{{ error.message }}
							</li>
						</ul>
						<span v-else-if="getParameterErrors(parameter.elasticacronym)[0].info" class="fs-regular">{{
							getParameterErrors(parameter.elasticacronym)[0].message + '. ' + getParameterErrors(parameter.elasticacronym)[0].info
						}}</span>
						<span v-else class="fs-regular">{{ getParameterErrors(parameter.elasticacronym)[0].message }}</span>
					</v-tooltip>
					<v-tooltip
						right
						v-else-if="statusProperties[parameter.elasticacronym]"
						nudge-right="5"
						transition="scroll-x-transition"
						color="00000099"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-col
								cols="5"
								:id="parameter.elasticacronym + '-value'"
								class="d-flex flex-nowrap justify-center align-center pollutant-value pa-0"
								style="cursor: pointer"
								v-bind="attrs"
								v-on="on"
							>
								<span v-if="parameter.showstatusname == 1" class="fw-semi fs-regular text-center">
									{{ statusProperties[parameter.elasticacronym].name }}
								</span>
								<span v-else class="fw-semi fs-regular text-center">{{ parameterValues[parameter.elasticacronym] }}</span>
							</v-col>
						</template>
						<span v-if="parameter.showstatusname == 1" class="fw-500 fs-regular">{{ parameterValues[parameter.elasticacronym] }}</span>
						<span v-else class="fw-500 fs-regular text-center">{{ statusProperties[parameter.elasticacronym].name }}</span>
					</v-tooltip>
					<v-col v-else cols="5" class="d-flex flex-nowrap justify-center align-center pollutant-value pa-0">
						<span class="fw-semi fs-regular text-center">{{ parameterValues[parameter.elasticacronym] }}</span>
					</v-col>
				</v-row>
			</v-container>
			<!-- <v-container class="pa-0 mt-2 px-2 border-t" v-if="hasAQI || hasCAQI">
				<v-list lines="one" density="compact" class="py-0">
					<div class="d-flex justify-center align-center width-100">
						<span class="color-high fw-600 fs-regular ma-0 px-0 py-1">
							<v-icon color="var(--high)" size="18" class="pr-2">fak fa-aqi</v-icon>
							{{ $t('panels.featurePopup.calculated') }}
						</span>
					</div>
				</v-list>

				<v-row no-gutters class="pa-1" v-if="hasAQI">
					<v-tooltip left nudge-left="5" transition="scroll-x-transition" color="00000099">
						<template v-slot:activator="{ on, attrs }">
							<v-col
								cols="7"
								class="d-flex flex-nowrap justify-start align-center pa-0"
								style="cursor: pointer"
								v-bind="attrs"
								v-on="on"
							>
								<span class="fw-500 fs-regular">
									{{ $t('panels.featurePopup.aqi') }} <i class="fak fa-line-info-2 ps-1 fs-sm fw-regular"></i>
								</span>
							</v-col>
						</template>
						<span class="fs-regular">{{ $t('panels.featurePopup.aqiDesc') }}</span>
					</v-tooltip>
					<v-tooltip right nudge-right="5" transition="scroll-x-transition" color="00000099">
						<template v-slot:activator="{ on, attrs }">
							<v-col
								cols="5"
								class="d-flex flex-nowrap justify-center align-center pollutant-value pa-0"
								style="cursor: pointer"
								v-bind="attrs"
								v-on="on"
								:style="{ backgroundColor: aqiDescription ? aqiDescription.colorcalc + 'BF' : aqiColor(calculatedAQI.value) + 'BF' }"
							>
								<span class="fw-semi fs-regular text-center">
									{{ calculatedAQI.value }}
								</span>
							</v-col>
						</template>
						<span class="fw-500 fs-regular">{{
							aqiDescription ? aqiDescription.name : $t('panels.featurePopup.aqiValues.' + calculatedAQI.value)
						}}</span>
					</v-tooltip>
				</v-row>
				<v-row no-gutters class="pa-1" v-if="hasCAQI">
					<v-tooltip left nudge-left="5" transition="scroll-x-transition" color="00000099">
						<template v-slot:activator="{ on, attrs }">
							<v-col
								cols="7"
								class="d-flex flex-nowrap justify-start align-center pa-0"
								style="cursor: pointer"
								v-bind="attrs"
								v-on="on"
							>
								<span class="fw-500 fs-regular">
									{{ $t('panels.featurePopup.caqi') }} <i class="fak fa-line-info-2 ps-1 fs-sm fw-regular"></i>
								</span>
							</v-col>
						</template>
						<span class="fs-regular">{{ $t('panels.featurePopup.caqiDesc') }}</span>
					</v-tooltip>
					<v-tooltip right nudge-right="5" transition="scroll-x-transition" color="00000099">
						<template v-slot:activator="{ on, attrs }">
							<v-col
								cols="5"
								class="d-flex flex-nowrap justify-center align-center pollutant-value pa-0"
								style="cursor: pointer"
								v-bind="attrs"
								v-on="on"
								:style="{
									backgroundColor: caqiDescription ? caqiDescription.colorcalc + 'BF' : aqiColor(calculatedCAQI.value) + 'BF'
								}"
							>
								<span class="fw-semi fs-regular text-center">
									{{ calculatedCAQI.value }}
								</span>
							</v-col>
						</template>
						<span class="fw-500 fs-regular">{{
							caqiDescription ? caqiDescription.name : $t('panels.featurePopup.aqiValues.' + calculatedCAQI.value)
						}}</span>
					</v-tooltip>
				</v-row>
			</v-container> -->
			<v-container class="pa-0 mt-2 px-2 date-container" v-if="parametersNotProcessed.length > 0">
				<v-list lines="one" density="compact" class="py-0 date-container">
					<v-tooltip left nudge-left="5" nudge-bottom="25" max-width="350" transition="scroll-x-transition" color="00000099">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on" class="pointer d-flex justify-center align-center width-100">
								<i class="fas fa-do-not-enter pr-2"></i>
								<span class="date-text fw-600 fs-regular ma-0 px-0 py-1">
									{{ $t('panels.featurePopup.notProcessed') }}
								</span>
							</div>
						</template>
						<span class="fw-500 fs-regular text-center">{{ parametersNotProcessed[0].message }}</span>
					</v-tooltip>
				</v-list>

				<v-row no-gutters class="pa-1" v-for="(parameterNP, i) in parametersNotProcessed" :key="i">
					<v-col cols="7" class="d-flex flex-nowrap justify-start align-center pa-0">
						<span class="fw-regular fs-regular">{{ parameterNP.parameter }}</span>
					</v-col>
					<v-col
						cols="5"
						:id="parameterNP.parameter + '-NOT_PROCESSED'"
						class="d-flex flex-nowrap justify-center align-center pollutant-value pa-0"
						style="cursor: pointer"
					>
						<span v-if="typeof parameterNP.value == 'number'" class="fw-semi fs-regular text-center">
							{{ parameterNP.value.toFixed(2) }}
							<!-- <i class="fak fa-solid-alert ps-1 fs-sm fw-regular" style="color: var(--rojo-100) !important"></i> -->
						</span>
						<span v-else class="fw-semi fs-regular text-center">
							{{ parameterNP.value }}
							<!-- <i class="fak fa-solid-alert ps-1 fs-sm fw-regular" style="color: var(--rojo-100) !important"></i> -->
						</span>
					</v-col>
					<!-- <v-tooltip right nudge-right="5" max-width="350" transition="scroll-x-transition" color="00000099">
						<template v-slot:activator="{ on, attrs }">
							<v-col
								cols="5"
								:id="parameterNP.parameter + '-NOT_PROCESSED'"
								class="d-flex flex-nowrap justify-center align-center pollutant-value pa-0"
								style="cursor: pointer"
								v-bind="attrs"
								v-on="on"
							>
								<span class="fw-semi fs-regular text-center">
									{{ parameterNP.value.toFixed(2) }}
									<i class="fak fa-solid-alert ps-1 fs-sm fw-regular" style="color: var(--rojo-100) !important"></i>
								</span>
							</v-col>
						</template>
						<span class="fw-500 fs-regular text-center">{{ parameterNP.message }}</span>
					</v-tooltip> -->
				</v-row>
			</v-container>

			<v-container class="switchoff d-flex flex-column align-center justify-center px-0 py-2" v-else-if="noRealTime">
				<v-icon size="1.5rem"> fal fa-inbox </v-icon>
				<div class="switchoff-text">{{ $t('common.noRealTimeData') }}</div>
			</v-container>
			<v-progress-linear :indeterminate="true" :color="primaryColor" v-else-if="loadingPopup"></v-progress-linear>
			<div class="d-flex justify-center align-center pa-2">
				<v-btn elevation="0" class="btn-details" id="featureMoreInfo" :disabled="noRealTime || loadingPopup" @click="openForm(feature)">
					<v-icon size="16" class="fw-500" aria-hidden="true">fak fa-line-statistics</v-icon>
					<span class="btn-details-text fw-bold fs-regular" style="padding: 5px">{{ $t('common.moreInfo') }}</span>
				</v-btn>
			</div>
		</v-card>
	</div>
</template>

<script>
import { CARD_HEIGHT } from '@/lib/variables/panels';

import constants from '@/utils/constants';
import ol2map from '../subcomponents/ol2map';
// eslint-disable-next-line no-unused-vars
import { CENTER, INITIAL_ZOOM } from '../subcomponents/mapVariables';
import { getDateByFormatandTimeZone, getStringFeatureImage, obtainIndexName, calculateCAQI, calculateAQI, getColorAQI, getStatusProperties } from '@/api/common';
//import SvgNotification from '@/components/map/icon/SvgNotification';
import { getAggs } from '@/api/databases_API';
import { query_AQI, query_CAQI } from '@/utils/queries';

export default {
	components: {
		/* SvgNotification */
	},
	props: {
		feature: {
			type: Object,
			required: false
		},
		featureCoordinates: {
			type: Array,
			required: false
		},
		id: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			satelliteType: constants.DATASOURCETYPE_SATELLITE,
			satelliteModel: constants.SATELLITE_MODEL,
			primaryColor: this.$store.state.global.primaryColor,
			statusProperties: {},
			parameterValues: {},
			notificationsByType: null,
			iconsUrl: this.$store.getters.getUrlBaseIcons,
			parameterValidations: [],
			parametersNotProcessed: [],
			parametersEarly: [],
			parametersLate: [],
			calculatedCAQI: null,
			calculatedAQI: null,
			hasAQI: false,
			hasCAQI: false
		};
	},
	computed: {
		featureNotifications() {
			if (this.feature && this.$store.getters.getNotifications) {
				return this.$store.getters.getNotifications.filter(
					(notification) => notification.pmdatasourceinstanceid == this.feature.pmdatasourceinstanceid
				);
			} else {
				return false;
			}
		},
		isParameters() {
			return Object.keys(this.parameters).length > 0;
			//return Object.keys(this.$store.getters.getParameters).length > 0;
		},
		parameters() {
			if (this.feature) {
				return this.$store.getters.getParameters.filter(
					(parameter) =>
						parameter.visible !== 0 &&
						parameter.pmorganizationid == this.feature.pmorganizationid &&
						parameter.pmdatasourcetypeid == this.feature.pmdatasourcetypeid
				);
			} else {
				return {};
			}
		},
		longitude() {
			return this.decimalToDegreeMinuteSecond(this.feature.longitude);
		},
		latitude() {
			return this.decimalToDegreeMinuteSecond(this.feature.latitude);
		},
		popupElasticData() {
			return this.$store.getters.getPopupElasticData;
		},
		featureClick: {
			get() {
				return this.$store.getters.getFeatureClick;
			},
			set(value) {
				this.$store.commit('setFeatureClick', value);
			}
		},
		selectedTabDevice: {
			get() {
				return this.$store.getters.getSelectedTabDevice;
			},
			set(value) {
				this.$store.commit('setSelectedTabDevice', value);
			}
		},
		selectedTabHistoric: {
			get() {
				return this.$store.getters.getSelectedTabHistoric;
			},
			set(value) {
				this.$store.commit('setSelectedTabHistoric', value);
			}
		},
		selectedTabNotification: {
			get() {
				return this.$store.getters.getSelectedTabNotification;
			},
			set(value) {
				this.$store.commit('setSelectedTabNotification', value);
			}
		},
		realTimeElasticData: {
			get() {
				return this.$store.getters.getRealTimeElasticData;
			},
			set(value) {
				this.$store.commit('setRealTimeElasticData', value);
			}
		},
		loadingPopup: {
			get() {
				return this.$store.getters.getLoadingPopup;
			},
			set(value) {
				this.$store.commit('setLoadingPopup', value);
			}
		},
		parameterStatus: {
			get() {
				return this.$store.getters.getParameterStatus;
			},
			set(value) {
				this.$store.commit('setParameterStatus', value);
			}
		},
		noRealTime() {
			return (
				this.feature.spatialmodel == constants.SATELLITE_MODEL ||
				this.feature.elasticacronym == constants.DATASOURCETYPE_SATELLITE ||
				typeof this.popupElasticData == 'number'
			);
		},
		featureType: {
			get() {
				return this.$store.getters.getFeatureType;
			},
			set(value) {
				this.$store.commit('setFeatureType', value);
			}
		},
		displayMode: {
			get() {
				return this.$store.getters.getDisplayMode;
			},
			set(value) {
				this.$store.commit('setDisplayMode', value);
			}
		},
		aqiDescription() {
			if (this.calculatedAQI) return this.$store.getters.getAqiDescriptions.find((aqi) => aqi.pmaqidescriptionid == this.calculatedAQI.value);
			return false;
		},
		caqiDescription() {
			if (this.calculatedCAQI) return this.$store.getters.getAqiDescriptions.find((aqi) => aqi.pmaqidescriptionid == this.calculatedCAQI.value);
			return false;
		}
	},
	watch: {
		popupElasticData: {
			// eslint-disable-next-line no-unused-vars
			handler(newValue, oldValue) {
				console.log('debug elastic data', oldValue, newValue, typeof newValue);
				this.parameterValidations = this.parametersNotProcessed = this.parametersEarly = this.parametersLate = [];
				if (newValue && Object.hasOwn(newValue, 'device')) {
					this.parameterValidations = this.getValidationErrors();
					if (this.parameterValidations.length > 0) {
						this.parametersNotProcessed = this.parameterValidations.filter(
							(validation) => validation.acronym == constants.PARAMETER_NOT_PROCESSED
						);
						this.parametersEarly = this.parameterValidations.filter((validation) => validation.acronym == constants.FREQUENCY_EARLY);
						this.parametersLate = this.parameterValidations.filter((validation) => validation.acronym == constants.FREQUENCY_LATE);
						this.sortParameters();
						console.log('debug validations', this.parameterValidations, this.parametersEarly, this.parametersLate);
					}

					if (this.isParameters) {
						this.parameters.forEach((parameter) => {
							this.parameterValues[parameter.elasticacronym] = this.filterParameterElasticData(parameter);
						});
						console.log('debug: watcher values', this.parameterValues, this.statusProperties);
					}
					//this.getDataAQI();
				}
			},
			deep: true
		},
		featureNotifications: {
			// eslint-disable-next-line no-unused-vars
			handler(newValue, oldValue) {
				//console.log('debug feature notifications', oldValue, newValue);
				if (newValue) this.notificationsByType = this.groupNotificationsByType(this.featureNotifications);
			},
			deep: true
		}
	},
	mounted() {
		//console.log('debug mounted popup', this.parameterValues);
	},
	updated() {
		/* if (this.isParameters) {
			this.parameters.forEach((parameter) => {
				this.parameterValues[parameter.elasticacronym] = this.filterParameterElasticData(parameter);
			});
			console.log('debug updated values', this.parameterValues, this.statusProperties);
		} */
		console.log('debug updated popup', this.popupElasticData, document.getElementById('LAeq-value'));
		this.updateStatusColor();
		//console.log('popupElasticData: ', this.popupElasticData, 'isParameters: ', this.isParameters);
	},
	methods: {
		featureHelp() {},
		decimalToDegreeMinuteSecond(degrees) {
			return [
				0 | degrees,
				'° ',
				0 | (((degrees = (degrees < 0 ? -degrees : degrees) + 1e-4) % 1) * 60),
				"' ",
				0 | (((degrees * 60) % 1) * 60),
				'"'
			].join('');
		},

		openForm(feature) {
			//console.log('Open form', feature, this.featureClick);
			if (feature) {
				if (this.featureCoordinates) {
					//console.log('debug center coordinates', this.featureCoordinates, ol2map.map.getView(), ol2map.map.getSize());
				}
				//ol2map.map.getView().centerOn(this.featureCoordinates, ol2map.map.getSize());

				var view = ol2map.map.getView();

				view.animate({
					center: this.featureCoordinates
					//zoom: INITIAL_ZOOM
				});
				if (!this.featureClick || this.featureClick.code != feature.code) {
					this.closeFeature();
					this.featureClick = feature;
					this.selectedTabDevice = constants.FEATURE_TABS.REAL_TIME;
					this.selectedTabHistoric = 0;
					this.$store.commit('setSelectedTabAdvanced', 'advanced-device');
					this.selectedTabNotification = 0;
					this.realTimeElasticData = [];
					this.actionsToggled = false;

					// Define feature type only when opening the form
					if (Object.hasOwn(feature, constants.NOTIFICATIONS_ID)) {
						this.$store.commit('setFeatureType', constants.FEATURE_TYPE_NOTIFICATION);
					} else {
						this.$store.commit('setFeatureType', constants.FEATURE_TYPE_DEVICE);
					}

					if (document.getElementsByClassName('card-content-container')[0]) {
						document.getElementsByClassName('card-content-container')[0].style.display = 'block';
					}
					document.documentElement.style.setProperty('--map-control-bottom-position', CARD_HEIGHT + 'px');
				}
			} else {
				this.featureClick = false;
				document.documentElement.style.setProperty('--map-control-bottom-position', '0px');
			}
		},
		filterParameterElasticData(parameter) {
			let response = this.$t('common.noData');
			console.log(
				'parameter status',
				parameter.name,
				this.parameterStatus,
				this.parameterStatus.find((status) => status.pmparameterid == parameter.pmparameterid)
			);
			try {
				let symbol = parameter.symbol && parameter.symbol.trim() != '' ? parameter.symbol : false;
				if (this.statusProperties[parameter.elasticacronym]) {
					this.statusProperties[parameter.elasticacronym] = null;
				}
				if (this.popupElasticData && Object.hasOwn(this.popupElasticData, parameter.elasticacronym)) {
					let elasticSource = this.popupElasticData;
					let filteredStatus = null;
					switch (parameter.unittype) {
						case constants.INTEGER:
							filteredStatus = this.parameterStatus.find((status) => status.pmparameterid == parameter.pmparameterid);
							if (filteredStatus) {
								const statusObj = getStatusProperties(filteredStatus, elasticSource[parameter.elasticacronym]);
								if (statusObj) {
									this.statusProperties[parameter.elasticacronym] = statusObj;
								}
							}

							response = symbol ? elasticSource[parameter.elasticacronym] + ' ' + symbol : elasticSource[parameter.elasticacronym];
							break;

						case constants.FLOAT:
							filteredStatus = this.parameterStatus.find((status) => status.pmparameterid == parameter.pmparameterid);
							if (filteredStatus) {
								const statusObj = getStatusProperties(filteredStatus, elasticSource[parameter.elasticacronym]);
								if (statusObj) {
									this.statusProperties[parameter.elasticacronym] = statusObj;
								}
							}
							if (typeof elasticSource[parameter.elasticacronym] == 'number')
								response = symbol
									? elasticSource[parameter.elasticacronym].toFixed(2) + ' ' + symbol
									: elasticSource[parameter.elasticacronym].toFixed(2);
							else response = symbol ? elasticSource[parameter.elasticacronym] + ' ' + symbol : elasticSource[parameter.elasticacronym];
							break;
						case constants.BOOLEAN:
							// TODO: Modify parameter status to accept boolean values
							if (typeof elasticSource[parameter.elasticacronym] == 'number') {
								if (elasticSource[parameter.elasticacronym] === 0) {
									response = this.$t('general.no');
								} else if (elasticSource[parameter.elasticacronym] === 1) {
									response = this.$t('general.yes');
								}
							} else if (typeof elasticSource[parameter.elasticacronym] == 'boolean') {
								if (elasticSource[parameter.elasticacronym] === false) {
									response = this.$t('general.no');
								} else if (elasticSource[parameter.elasticacronym] === true) {
									response = this.$t('general.yes');
								}
							}

							break;
						case constants.STRING:
							response = symbol ? elasticSource[parameter.elasticacronym] + ' ' + symbol : elasticSource[parameter.elasticacronym];
							break;
						default:
							break;
					}
				}
				/* console.log(
					'filterParameterElasticData: ',
					parameter.elasticacronym,
					parameter.unittype,
					typeof this.popupElasticData[parameter.elasticacronym],
					this.popupElasticData
				); */
				return response;
			} catch (err) {
				console.log('ERROR: ', err);
				return response;
			}

			//console.log('debug ' + parameter.acronym, this.popupElasticData);
		},

		getElasticSearchDate() {
			console.log('Elastic document: ', this.popupElasticData);
			const timestamp = this.popupElasticData.dateObserved_timestamp;
			//console.log(timestamp);

			const timezone = this.popupElasticData.timezone;
			//console.log('timezone', timezone);

			//return getDateByFormat(timestamp, this.userProperties.acronymdateformat);
			return getDateByFormatandTimeZone(timestamp, timezone, this.userProperties.constants);
		},
		getBackgroundColor(elasticacronym) {
			if (this.statusProperties[elasticacronym]) {
				return { backgroundColor: this.statusProperties[elasticacronym] };
			}
			return null;
		},
		updateStatusColor() {
			//console.log('debug parameters', this.parameters, this.parameters.length);
			if (this.parameters.length > 0) {
				this.parameters.forEach((parameter) => {
					if (this.statusProperties[parameter.elasticacronym] && document.getElementById(parameter.elasticacronym + '-value')) {
						document.getElementById(parameter.elasticacronym + '-value').style.backgroundColor =
							this.statusProperties[parameter.elasticacronym].color + 'BF'; //75% Opacity
					}
				});
			}
		},
		filterParameterStatus(parameter, value) {
			return this.parameterStatus.filter(
				(status) => status.pmparameterid == parameter.pmparameterid && status.minvalue <= value && value < status.maxvalue
			);
		},
		groupNotificationsByType(notifications) {
			const object = notifications.reduce((r, { severityname, severitycolor, iconroute, ...rest }) => {
				if (!r[severityname])
					r[severityname] = {
						severityname,
						severitycolor,
						iconroute,
						notifications: [{ severityname, severitycolor, iconroute, ...rest }]
					};
				else r[severityname].notifications.push({ severityname, severitycolor, iconroute, ...rest });
				return r;
			}, {});
			//console.log('debug: group notif', object);
			return object;
		},
		getNotificationIcon(iconRoute) {
			return getStringFeatureImage(this.iconsUrl, iconRoute);
		},
		getValidationErrors() {
			//console.log('debug popup validations ' + elasticacronym, this.popupElasticData.validations, this.popupElasticData);
			if (Object.hasOwn(this.popupElasticData, 'validations')) {
				//console.log('debug popup validations parameter', this.popupElasticData.validations);
				return this.popupElasticData.validations;
			}
			//console.log('return ', error);
			return [];
		},
		getParameterErrors(elasticacronym) {
			if (this.parameterValidations.length > 0)
				return this.parameterValidations.filter(
					(validation) =>
						validation.parameter == elasticacronym &&
						validation.acronym != constants.FREQUENCY_LATE &&
						validation.acronym != constants.FREQUENCY_EARLY
				);
			return [];
		},
		closeFeature() {
			this.featureClick = null;
			this.parametersNotProcessed = [];
			this.parametersBadDataType = {};
			document.documentElement.style.setProperty('--map-control-bottom-position', '0px');
			this.displayMode = 'card';
			this.$store.commit('cleanFormHistoric');
			this.$store.commit('cleanFormAdvancedChart');
			console.warn('formHistoric close', this.$store.getters.getHistoricStore);
		},
		getParameterName(elasticacronym) {
			let dtsParameters = this.$store.getters.getParameters.filter(
				(parameter) =>
					parameter.pmorganizationid == this.feature.pmorganizationid && parameter.pmdatasourcetypeid == this.feature.pmdatasourcetypeid
			);
			let parameter = dtsParameters.find((parameter) => parameter.elasticacronym == elasticacronym);
			if (parameter) return parameter.name;
			return false;
		},
		async sortParameters() {
			this.parametersEarly.sort((a, b) => {
				const elementA = a.acronym.toUpperCase();
				const elementB = b.acronym.toUpperCase();
				return elementA.localeCompare(elementB);
			});
			this.parametersLate.sort((a, b) => {
				const elementA = a.acronym.toUpperCase();
				const elementB = b.acronym.toUpperCase();
				return elementA.localeCompare(elementB);
			});
		},
		async getDataAQI() {
			let aqiPromises = [];
			const index = obtainIndexName(this.feature);
			const code = this.feature.code;
			aqiPromises.push(getAggs(index, query_CAQI(code)));
			aqiPromises.push(getAggs(index, query_AQI(code)));
			console.log('debug AQI & CAQI: ', this.feature, index, query_AQI(code));
			Promise.all(aqiPromises).then((results) => {
				console.log('results AQI & CAQI: ', results);
				// Check parameters data for CAQI
				let resultCAQI = results[0].message;
				let hasCAQI = true;
				Object.keys(resultCAQI).forEach((max) => {
					console.log('debug CAQi max', max, resultCAQI[max].value, hasCAQI);
					if (!resultCAQI[max].value) {
						hasCAQI = false;
					}
				});
				// Check parameters data for AQI
				let resultAQI = results[1].message;
				let hasAQI = true;
				Object.keys(resultAQI).forEach((max) => {
					console.log('debug AQi max', max, resultAQI[max].value);
					if (!resultAQI[max].value) hasAQI = false;
				});
				if (hasCAQI) this.calculatedCAQI = calculateCAQI(resultCAQI);
				if (hasAQI) this.calculatedAQI = calculateAQI(resultAQI);
				this.hasCAQI = hasCAQI;
				this.hasAQI = hasAQI;
				console.log(
					'deb aqi & caqi',
					this.calculatedAQI,
					this.calculatedCAQI,
					this.$store.getters.getAqiDescriptions,
					this.aqiDescription,
					this.caqiDescription
				);
			});
		},
		aqiColor(aqi) {
			return getColorAQI(aqi);
		},
		openActiveNotifications(feature) {
			this.openForm(feature);
			this.selectedTabDevice = constants.FEATURE_TABS.ACTIVE_NOTIFICATIONS;
			/* setTimeout(() => {
				this.selectedTabDevice = constants.FEATURE_TABS.ACTIVE_NOTIFICATIONS;
				console.log('selected test');
			}, 200); */
		}
	}
};
</script>
<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';

.ol-popup {
	width: fit-content;
	max-width: 550px; /* Establece un ancho máximo para limitar el crecimiento */
	min-width: 320px !important;
}

.border-top {
	border-top: 1px solid var(--low);
}
.border-bottom {
	border-bottom: 1px solid var(--low);
}

.card-title {
	background-color: var(--azul-primary-100);
	.card-title-text {
		font-size: 22px !important;
		color: #fff;
	}
	.card-subtitle-text {
		font-size: 1rem !important;
	}
}
.switchoff {
	color: var(--gris-300);
	& .switchoff_icon {
		font-size: 2rem !important;
	}
	& .switchoff-text {
		font-size: 1rem !important;
	}
}
.date-container {
	background-color: var(--fondo-1);
	& > * {
		color: var(--gris-300);
	}
}

.btn-details {
	background-color: var(--gris-50) !important;
	width: 100%;
	& > span.btn-details-text {
		color: #000;
		font-size: 1rem !important;
	}
	& > i.fa-chart-bar {
		color: #000;
	}
}

.pollutant-value {
	border-radius: 4px;
}
</style>
